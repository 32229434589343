import type { AuditTrailService } from './AuditTrailService';

export abstract class AuditTrailEvent {
  abstract entityType: string;
  abstract entityId: string;
  abstract createdAt: string;

  markAsSeen = () => {
    this.service.markAsSeen(this);
  };

  get hasBeenSeen() {
    return this.service.hasBeenSeen(this);
  }

  protected service: AuditTrailService;

  protected constructor(service: AuditTrailService) {
    this.service = service;
  }
}
