import { AnyFunction } from '../type-utils/AnyFunction';

export const once = <F extends AnyFunction>(fn: F) => {
  let called = false;
  let returned: ReturnType<F>;

  return (...args: Parameters<F>): ReturnType<F> => {
    if (called) return returned;
    called = true;
    returned = fn(...args);
    return returned;
  };
};
