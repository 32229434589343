import { Sort } from 'assets/icons';
import { Button, Tab } from 'components';
import { compareAsc, compareDesc } from 'date-fns';
import { useLocalisation } from 'providers';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { AuditTrailEvent } from 'services';

import type { Nullable } from '@yourxx/support';

import { Hint } from '../../CommonLayout';
import {
  Body,
  Card,
  Contents,
  GroupDate,
  RefreshButton,
  StyledLoader,
  StyledSlideInOut,
  StyledTabs,
  Toolbar
} from './styling';
import { auditTrailDateFormat, compareEventDates, formatWithTimezone } from './utils';

type SortDir = 'asc' | 'desc';
type TabEntry = {
  value: string;
  label: string;
  onActive?: VoidFunction;
  render?: (props: { sortDir: SortDir }) => ReactNode;
};

interface AuditTrailPanelProps<E extends AuditTrailEvent> {
  isLoading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  events: Nullable<ReadonlyArray<E>>;
  onRefresh: VoidFunction;
  additionalTabs?: readonly TabEntry[];
  render: (event: E) => ReactNode;
}

export const AuditTrailPanel = <E extends AuditTrailEvent>({
  isLoading,
  isOpen,
  onClose,
  events,
  onRefresh,
  render,
  additionalTabs
}: AuditTrailPanelProps<E>) => {
  const [str] = useLocalisation();
  const [selectedTab, setSelectedTab] = useState('activity');
  const [sortDir, setSortDir] = useState<SortDir>('desc');
  const [_, setRerenderTimestamp] = useState(new Date());
  const forceRerender = () => setRerenderTimestamp(new Date());

  const tabs = useMemo<TabEntry[]>(() => {
    const activityTab = { value: 'activity', label: str('AssortmentFinalization.auditTrail.tabs.activity') };
    return [activityTab, ...(additionalTabs ?? [])];
  }, [additionalTabs, str]);

  const activeTab = useMemo(() => tabs.find(tab => tab.value === selectedTab), [selectedTab, tabs]);

  useEffect(() => activeTab?.onActive?.(), [activeTab]);

  const eventGroupComparer = useMemo(() => compareEventDates(sortDir), [sortDir]);

  const sortedEvents = useMemo(
    () =>
      [...(events ?? [])]?.sort((a, b) =>
        sortDir === 'asc' ? compareAsc(a.createdAt, b.createdAt) : compareDesc(a.createdAt, b.createdAt)
      ),
    [events, sortDir]
  );

  return (
    <StyledSlideInOut
      heading={
        <>
          {str('AssortmentFinalization.auditTrail.title')}
          {isLoading && <StyledLoader />}
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      width={350}
      headerControls={
        <Hint
          of={
            <span>
              <RefreshButton onClick={onRefresh} />
            </span>
          }
        >
          {str('general.refresh')}
        </Hint>
      }
    >
      <Body>
        <Toolbar>
          <StyledTabs>
            {tabs.map(({ value, label }) => (
              <Tab
                key={value}
                value={value}
                onClick={() => setSelectedTab(value)}
                isSelected={selectedTab === value}
                boldSelectedOnly
                borderWidth={2}
              >
                {label}
              </Tab>
            ))}
          </StyledTabs>
          <Button onClick={() => setSortDir(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
            <Sort />
          </Button>
        </Toolbar>
        <Contents>
          {selectedTab === 'activity' && (
            <>
              {!events?.length && !isLoading && <p>{str('AssortmentFinalization.auditTrail.noActivity')}</p>}
              <Virtuoso
                data={sortedEvents}
                itemContent={(i, event) => {
                  const previousEvent = sortedEvents[i - 1];
                  const isFirst = i === 0;

                  return (
                    <Card
                      onMouseEnter={() => {
                        event.markAsSeen();
                        setTimeout(forceRerender, 0);
                      }}
                      key={`${event.createdAt}-${i}`}
                      $isNew={!event.hasBeenSeen}
                      $display="block"
                      $hasGroupDate={
                        isFirst || (previousEvent && eventGroupComparer(previousEvent.createdAt, event.createdAt))
                      }
                    >
                      {isFirst && (
                        <GroupDate>
                          <span>{formatWithTimezone(event.createdAt, auditTrailDateFormat)}</span>
                          {!events?.[0].hasBeenSeen && (
                            <span>{str('AssortmentFinalization.auditTrail.sinceLast')}</span>
                          )}
                        </GroupDate>
                      )}
                      {previousEvent && eventGroupComparer(previousEvent.createdAt, event.createdAt) && (
                        <GroupDate>
                          <span>{formatWithTimezone(event.createdAt, auditTrailDateFormat)}</span>
                        </GroupDate>
                      )}
                      <span>{formatWithTimezone(event.createdAt, 'hh:mm a')}</span>
                      {render(event)}
                    </Card>
                  );
                }}
              />
            </>
          )}
          {activeTab?.render?.({ sortDir })}
        </Contents>
      </Body>
    </StyledSlideInOut>
  );
};
