import { chunkArray, mergeArraysInNestedObjects } from '@yourxx/support';
import { CanvasProductDetails, type StoryImage } from '@yourxx/types';
import {
  ApiAddDeleteProductIn,
  ApiGetProductIn,
  ApiGetProductOut,
  ApiUpdateDeleteProductOut,
  ApiUpdateProductIn,
  PageDetails,
  ProductDetails,
  UIProduct
} from '@yourxx/types';

import { apiEndpoints, checkLineAssortmentId, checkLineAssortmentIdFromId, mapProduct, toRequest } from '../../utils';
import { GetProductDetailsRequest } from '../canvas/types';
import { apiClient } from '../client';

export const removeProduct = async ({
  id,
  pc9s: inPc9s
}: ApiAddDeleteProductIn): Promise<ApiUpdateDeleteProductOut> => {
  const chunkedPc9s = chunkArray(inPc9s, 5000);
  let response: ApiUpdateDeleteProductOut = {} as ApiUpdateDeleteProductOut;
  for (const pc9s of chunkedPc9s) {
    const request = toRequest({
      path: apiEndpoints.product,
      params: { id },
      body: { products: pc9s },
      method: 'DELETE'
    });
    response = mergeArraysInNestedObjects(response, await apiClient.request<ApiUpdateDeleteProductOut>(request));
  }
  return response;
};

export const updateProduct = async ({
  id,
  products: inProducts
}: ApiUpdateProductIn): Promise<ApiUpdateDeleteProductOut> => {
  const chunkedProducts = chunkArray(inProducts, 5000);
  let response: ApiUpdateDeleteProductOut = {} as ApiUpdateDeleteProductOut;
  for (const product of chunkedProducts) {
    const request = toRequest({
      path: apiEndpoints.product,
      params: { id },
      body: { products: product },
      method: 'POST'
    });
    response = mergeArraysInNestedObjects(response, await apiClient.request<ApiUpdateDeleteProductOut>(request));
  }
  return response;
};

export type GetProductRes<T extends ProductDetails> = {
  details: PageDetails;
  products?: UIProduct<T>[];
  storyImages?: StoryImage[];
};

export const getProducts = async <T extends ProductDetails>(
  params: ApiGetProductIn,
  signal?: AbortSignal
): Promise<GetProductRes<T>> => {
  checkLineAssortmentIdFromId(params);
  if (params.pc9 && Array.isArray(params.pc9)) {
    params.pc9 = params.pc9.join(' ');
  }
  if (params.status && Array.isArray(params.status)) {
    params.status = params.status.join(' ');
  }

  const request = toRequest({ path: apiEndpoints.product, params, method: 'GET', signal });
  const { products: toAddUrlProducts, details, storyImages } = await apiClient.request<ApiGetProductOut<T>>(request);

  const products: UIProduct<T>[] = mapProduct<T>({ products: toAddUrlProducts, details, storyImages });

  return { products, details };
};

export const getCanvasProductDetails = async (body: GetProductDetailsRequest): Promise<CanvasProductDetails[]> => {
  checkLineAssortmentId(body);
  const { pc9Codes } = body;
  const fetchItemsNestedArray: CanvasProductDetails[][] = await Promise.all(
    chunkArray(pc9Codes, 500).map(async (pc9Codes: string[]) => {
      const json = { ...body, pc9Codes };
      return apiClient.request<CanvasProductDetails[]>(
        toRequest({ path: apiEndpoints.canvasProductDetails, method: 'POST', body: json })
      );
    })
  );
  return fetchItemsNestedArray.flat();
};

//  Remove this in favour of updateProduct({ id, products: [{ pc9, video: videoUrl }] })
export const addProductVideo = async ({ pc9, videoUrl }: { pc9: string; videoUrl: string }): Promise<void> => {
  const response = await apiClient.request<{ error: string } | void>(
    toRequest({ path: apiEndpoints.video({ pc9 }), method: 'POST', body: { videoUrls: [videoUrl] } })
  );
  if (response && 'error' in response) throw new Error(response.error);
  return;
};
