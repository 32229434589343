import { Coathanger } from 'assets/icons';
import { useLocalisation } from 'providers';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const NoContent = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${themed('spacing.m')};
  max-width: ${rem(360)};
  padding: 0 ${themed('spacing.xxxl')};
  padding-bottom: ${rem(72)}; // Helps with optical alignment
  color: #6e6e6e; // Outside of theme
  text-align: center;

  > h2 {
    margin: 0;
    color: currentColor;
    ${themed('typography.h1')};
    font-weight: ${themed('font.weight.black')};
  }

  > p {
    margin: 0;
    color: currentColor;
    ${themed('typography.h4')};
    line-height: ${rem(18)};
  }
`;

export const NoOrdersAvailable = () => {
  const [str] = useLocalisation();
  return (
    <NoContent>
      <Coathanger />
      <h2>{str('Order.noOrders')}</h2>
      <p>{str('Order.noOrdersDetails')}</p>
    </NoContent>
  );
};
