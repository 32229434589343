import { Plus } from 'assets/icons';
import { IAM } from 'components';
import { GhostButton, SlotId } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useMemo, useState } from 'react';
import { type OrdersManager } from 'services';
import styled from 'styled-components';
import { themed } from 'utils';

import { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

import { OrderCreateEditModal, OrderDeleteModal, OrderFooter, OrderList, type OrderModalType } from '.';
import { NoOrdersAvailable } from './NoOrdersAvailable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${themed('color.offWhite')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }

  > div:first-of-type {
    flex: 1;
  }
`;

export interface OrderCaptureProps extends OrdersManager {
  locations?: CustomerShipTo[];
  finalAssortments: CustomerAssortmentArray[];
}

export const OrderCapture = ({
  isLoading,
  orders,
  finalAssortments,
  locations,
  createOrder,
  editOrder,
  deleteOrders,
  placeOrders
}: OrderCaptureProps) => {
  const [str] = useLocalisation();
  const [orderModalType, setOrderModalType] = useState<OrderModalType>();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [currentOrderId, setCurrentOrderId] = useState<string>();

  const currentOrder = useMemo(
    () => orders.find(({ orderId }) => orderId === currentOrderId),
    [currentOrderId, orders]
  );
  const selectedOrders = useMemo(
    () => orders.filter(({ orderId }) => selectedOrderIds.includes(orderId)),
    [orders, selectedOrderIds]
  );

  useSlot(
    SlotId.PageToolbarRight,
    useMemo(
      () => (
        <GhostButton onClick={() => setOrderModalType('create')}>
          <Plus />
          {str('Order.createOrder')}
        </GhostButton>
      ),
      [str]
    )
  );

  const closeModal = () => {
    setOrderModalType(undefined);
  };

  const showDeleteConfirmation = () => {
    setIsDeleting(true);
  };

  const closeDeleteConfirmation = () => {
    setIsDeleting(false);
  };

  useSlot(
    SlotId.Modals,
    <IAM action="orders.edit">
      {orderModalType && (
        <OrderCreateEditModal
          type={orderModalType}
          order={currentOrder}
          assortments={finalAssortments}
          locations={locations}
          onCreate={({ order, orderPerLocation }) => createOrder(order, orderPerLocation).finally(closeModal)}
          onEdit={({ order }) => editOrder(order).finally(closeModal)}
          onDelete={currentOrder ? showDeleteConfirmation : undefined}
          onClose={() => {
            setCurrentOrderId(undefined);
            closeModal();
          }}
          isLoading={isLoading}
        />
      )}
    </IAM>
  );

  useSlot(
    SlotId.Modals,
    <IAM action="orders.delete">
      {isDeleting && (
        <OrderDeleteModal
          orders={currentOrder ? [currentOrder] : selectedOrders}
          onConfirm={() =>
            deleteOrders(currentOrder ? [currentOrder] : selectedOrders)
              .then(() => {
                if (currentOrder) {
                  setCurrentOrderId(undefined);
                  setSelectedOrderIds(prev =>
                    prev.includes(currentOrder.orderId)
                      ? prev.filter(orderId => orderId !== currentOrder.orderId)
                      : prev
                  );
                } else {
                  setSelectedOrderIds([]);
                }
              })
              .finally(() => {
                closeModal();
                closeDeleteConfirmation();
              })
          }
          onClose={closeDeleteConfirmation}
          isLoading={isLoading}
        />
      )}
    </IAM>
  );

  return (
    <Wrapper>
      {!orders?.length ? (
        <NoOrdersAvailable />
      ) : (
        <>
          <OrderList
            orders={orders}
            selectedOrders={selectedOrders}
            setSelectedOrders={orders => setSelectedOrderIds(orders.map(o => o.orderId))}
            setCurrentOrder={order => setCurrentOrderId(order.orderId)}
            setOrderModalType={setOrderModalType}
          />
          <OrderFooter
            orders={orders}
            selectedOrders={selectedOrders.length}
            onDeselectAll={() => setSelectedOrderIds([])}
            viewSummary={() => console.log('view summary')}
            placeOrders={() => placeOrders(selectedOrders)}
            deleteOrders={showDeleteConfirmation}
          />
        </>
      )}
    </Wrapper>
  );
};
