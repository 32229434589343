import { Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { Image } from 'components/Image';
import { compareAsc, compareDesc, format } from 'date-fns';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { Card, GroupDate } from './styling';
import type { RemovedProduct } from './types';
import { auditTrailDateFormat, compareEventDates } from './utils';

const imageSize = '&width=256&height=256';

export const RemovedProducts = ({
  products,
  sortDir
}: {
  sortDir: 'asc' | 'desc';
  products: readonly RemovedProduct[];
}) => {
  const [str] = useLocalisation();
  const eventGroupComparer = useMemo(() => compareEventDates(sortDir), [sortDir]);
  const sortedProducts = useMemo(
    () => [...products].sort((a, b) => (sortDir === 'asc' ? compareAsc(a.date, b.date) : compareDesc(a.date, b.date))),
    [products, sortDir]
  );

  return (
    <>
      {!sortedProducts.length && <p>{str('AssortmentFinalization.auditTrail.noProducts')}</p>}
      <Virtuoso
        data={sortedProducts}
        itemContent={(i, currentProduct) => {
          const previousProduct = products[i - 1];
          const isFirst = i === 0;
          return (
            <Card
              key={currentProduct.id}
              $display="flex"
              $hasGroupDate={
                isFirst || (previousProduct && eventGroupComparer(previousProduct.date, currentProduct.date))
              }
              $isGreyedOut={currentProduct.status === 'DROP'}
            >
              {isFirst && (
                <GroupDate>
                  <span>{format(currentProduct.date, auditTrailDateFormat)}</span>
                </GroupDate>
              )}
              {previousProduct && eventGroupComparer(previousProduct.date, currentProduct.date) && (
                <GroupDate>
                  <span>{format(currentProduct.date, auditTrailDateFormat)}</span>
                </GroupDate>
              )}
              <Image src={`${currentProduct.image}${imageSize}`} />
              <div>
                <span>{currentProduct.pc9}</span>
                <h4>{currentProduct.title}</h4>
                {currentProduct.reason && (
                  <p>
                    <span>{str('AssortmentFinalization.auditTrail.reason')}: </span>
                    {currentProduct.reason}
                  </p>
                )}
              </div>
              {currentProduct.status !== 'DROP' && (
                <Button onClick={currentProduct.addBack}>
                  <Plus />
                  {str('AssortmentFinalization.auditTrail.addBack')}
                </Button>
              )}
              {currentProduct.status === 'DROP' && (
                <Button onClick={() => undefined}>{str('AssortmentFinalization.auditTrail.dropped')}</Button>
              )}
            </Card>
          );
        }}
      />
    </>
  );
};
