import { Edit, Plus, Remove } from 'assets/icons';
import { Button, Dropdown, Footer } from 'components';
import { useLocalisation } from 'providers';
import { useCallback, useMemo } from 'react';
import { useIAM } from 'utils';

import { removeFalsy } from '@yourxx/support';
import { ProductListData, UIProduct } from '@yourxx/types';

export const TopLevelCategoryBreakdown = <P extends { category0?: string; category1?: string }>({
  products
}: {
  products: ReadonlyArray<P>;
}) => {
  const [str] = useLocalisation();

  const categories = useMemo(
    () =>
      products.reduce((accumulator: Record<string, number>, product) => {
        if (product.category0) {
          accumulator[product.category0] = (accumulator[product.category0] || 0) + 1;
        }
        return accumulator;
      }, {}),
    [products]
  );

  return (
    <>
      {Object.entries(categories).map(([key, count], i, arr) => {
        const category = key?.toLowerCase() as 'tops' | 'bottoms' | 'accessories' | 'footwear';
        const label = count === 1 ? str(`general.${category}.singular`) : str(`general.${category}.plural`);
        const divider = i === arr.length - 1 ? '' : i === arr.length - 2 ? ` ${str('general.and')}` : ',';

        return (
          <span key={key}>
            {count as number} {label}
            {divider}
          </span>
        );
      })}
    </>
  );
};

export const ProductsFooter = ({
  isLine,
  isGlobalLine,
  products,
  selectedProducts,
  onDeselectAll,
  onEdit,
  onRemove,
  onAddToFA,
  onAddToAssortment,
  onAddToComparator,
  onAddToStory
}: {
  isLine: boolean;
  isGlobalLine: boolean;
  products: UIProduct<ProductListData>[];
  selectedProducts: number;
  onDeselectAll: VoidFunction;
  onEdit: VoidFunction;
  onRemove: VoidFunction;
  onAddToFA: VoidFunction;
  onAddToAssortment: VoidFunction;
  onAddToComparator: VoidFunction;
  onAddToStory: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const { canUse } = useIAM();
  const componentPrefix = 'footer';

  const {
    showEditProductsButton,
    showRemoveProductsButton,
    canAddToLineOrAssortment,
    canAddToFinalAssortment,
    canCreateCompareBoard,
    canAddSelectedToStory
  } = useMemo(() => {
    return {
      showEditProductsButton: canUse(`${componentPrefix}.editProduct`) && !isGlobalLine,
      showRemoveProductsButton: canUse(`${componentPrefix}.removeProduct`) && !isGlobalLine,
      canAddToLineOrAssortment: canUse(`${componentPrefix}.addToAssortment`) && !isGlobalLine,
      canAddToFinalAssortment: canUse(`${componentPrefix}.addToFinal`),
      canCreateCompareBoard: canUse('addToCompare', 'products.footer'),
      canAddSelectedToStory: canUse(`${componentPrefix}.addToStory`)
    };
  }, [canUse, isGlobalLine]);

  const addToDropdownItems = useMemo(
    () =>
      [
        canAddToLineOrAssortment && {
          key: 'assortment',
          label: str(`Assortment.pages.products.${isLine ? 'line' : 'assortment'}`)
        },
        canAddToFinalAssortment && { key: 'final', label: str('AssortmentFinalization.title') },
        canCreateCompareBoard && { key: 'comparator', label: str('Assortment.toolbar.button.compare') },
        canAddSelectedToStory && { key: 'story', label: str('general.story') }
      ].filter(removeFalsy),
    [canAddToLineOrAssortment, str, isLine, canAddToFinalAssortment, canCreateCompareBoard, canAddSelectedToStory]
  );
  const addToDropdownOnSelect = useCallback(
    (key: string) => {
      switch (key) {
        case 'assortment':
          return onAddToAssortment();
        case 'final':
          return onAddToFA();
        case 'comparator':
          return onAddToComparator();
        case 'story':
          return onAddToStory();
        default:
          return;
      }
    },
    [onAddToAssortment, onAddToFA, onAddToComparator, onAddToStory]
  );

  return (
    <Footer
      items={products}
      selectedItems={selectedProducts}
      onDeselectAll={onDeselectAll}
      breakdown={<TopLevelCategoryBreakdown products={products} />}
      rightActionButtons={
        <>
          {showEditProductsButton && (
            <Button onClick={onEdit}>
              <Edit />
              {str('Assortment.toolbar.button.editProduct')}
            </Button>
          )}
          {showRemoveProductsButton && (
            <Button onClick={onRemove}>
              <Remove />
              {str(`Assortment.toolbar.button.deleteProduct.${isLine ? 'line' : 'assortment'}`)}
            </Button>
          )}
          {addToDropdownItems.length > 0 && (
            <Dropdown
              trigger={
                <div>
                  <Plus />
                  {str('Assortment.toolbar.button.addTo')}
                </div>
              }
              disableSelectionHighlight
              items={addToDropdownItems}
              onSelect={addToDropdownOnSelect}
              showArrow={false}
              position="top"
              align="end"
            />
          )}
        </>
      }
    />
  );
};
