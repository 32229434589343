import { useMemo } from 'react';
import { Manifest } from 'utils';

import { getAuditTrail } from '@yourxx/ui-utils';

import { AssortmentAuditTrailEvent } from './AssortmentAuditTrailEvent';
import type { AuditTrailEvent } from './AuditTrailEvent';
import { type AuditTrailService, type Persistence, type SeenRecords } from './AuditTrailService';
import { OrderAuditTrailEvent } from './OrderAuditTrailEvent';
import { persistence } from './persistence';

// TODO: Figure out what API endpoint to call and move to UI utils package.
const getOrderAuditTrail = async ({ orderId: _orderId }: { orderId: string }) => {
  await new Promise(resolve => setTimeout(resolve, 1000));
  return [
    { name: 'order-placed', created_at: new Date('2025-01-07').toISOString() },
    { name: 'order-sized', created_at: new Date('2024-12-24').toISOString() },
    { name: 'order-sized', created_at: new Date('2024-12-23').toISOString() },
    { name: 'order-created', created_at: new Date('2024-12-23').toISOString() }
  ];
};

export interface Options {
  getAssortmentEvents: typeof getAuditTrail;
  getOrderEvents: typeof getOrderAuditTrail;
  persistence: Persistence;
}

const defaults: Options = {
  getAssortmentEvents: getAuditTrail,
  getOrderEvents: getOrderAuditTrail,
  persistence
};

export const createAuditTrailService = <E extends AuditTrailEvent>({
  getAssortmentEvents = defaults.getAssortmentEvents,
  getOrderEvents = defaults.getOrderEvents,
  persistence = defaults.persistence
}: Partial<Options> = defaults): AuditTrailService<E> => {
  let manifest = Manifest();
  const cache: {
    assortment: Record<string, Promise<AssortmentAuditTrailEvent[]>>;
    order: Record<string, Promise<OrderAuditTrailEvent[]>>;
  } = { assortment: {}, order: {} };
  const seenRecords: SeenRecords = persistence.load() ?? {};

  const service: AuditTrailService<E> = {
    markAsSeen: event => {
      if (event.createdAt <= (seenRecords[event.entityType] ??= {})[event.entityId]) return;
      seenRecords[event.entityType][event.entityId] = event.createdAt;
      persistence.save(seenRecords);
    },

    hasBeenSeen: event => event.createdAt <= seenRecords[event.entityType]?.[event.entityId],

    clearCache: command => {
      manifest = manifest.scheduleForReload(command.entityType, command.entityId);
    },

    isScheduledForReload: command => manifest.isScheduledForReload(command.entityType, command.entityId),

    loadEvents: ({ entityType, entityId }) => {
      const hasCachedData = !!cache[entityType][entityId];

      if (!hasCachedData || manifest.isScheduledForReload(entityType, entityId)) {
        manifest = manifest.remove(entityType, entityId);

        if (entityType === 'assortment') {
          cache[entityType][entityId] = getAssortmentEvents({ assortmentId: entityId }).then(entries =>
            entries.map(payload => AssortmentAuditTrailEvent.of(payload, service))
          );
        } else if (entityType === 'order') {
          cache[entityType][entityId] = getOrderEvents({ orderId: entityId }).then(entries =>
            entries.map(payload => OrderAuditTrailEvent.of(payload, service))
          );
        } else {
          throw new RangeError(`Unexpected entityType "${entityType}" passed when requesting Audit Trail events.`);
        }
      }

      return cache[entityType][entityId] as unknown as Promise<E[]>;
    }
  };

  return service;
};

export const useCreateAuditTrailService = (): AuditTrailService => useMemo(createAuditTrailService, []);
