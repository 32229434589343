import { ShowAsyncErrorAndGoBack, ViewTransition } from 'pages';
import { useSetupCustomerView } from 'pages/Customer';
import { OrderCapture } from 'pages/Landing/Assortments/OrderCapture';
import { useOrders } from 'providers';
import { Suspense } from 'react';
import { Await, Route, Routes } from 'react-router-dom';
import type { OrdersManager } from 'services';
import { styled } from 'styled-components';
import { parseSeasonParam, useEnsureRouteParams } from 'utils';

import type { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

import { CustomerOrderRoute } from '../CustomerOrder';
import { CustomerOrdersIndexSkeleton } from './CustomerOrdersIndexSkeleton';

export const CustomerOrdersIndex = (_props: { brand: string; customerId: string }) => {
  return (
    <Routes>
      <Route index Component={IndexRoute} />
      <Route path=":orderId/*" Component={CustomerOrderRoute} />
    </Routes>
  );
};

const IndexRoute = () => {
  const [customerId, season] = useEnsureRouteParams('customerId', 'season');
  return <Index customerId={customerId} season={season} />;
};

const Index = ({ customerId, season }: { customerId: string; season: string }) => {
  const { loadCustomerOrders, ...rest } = useOrders();
  const maybeSeason = parseSeasonParam(season);
  useSetupCustomerView({ customerId, season: maybeSeason, currentView: 'orders' });

  return (
    <Suspense fallback={<ViewTransition children={<CustomerOrdersIndexSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadCustomerOrders(season)}
        children={_ => <ViewTransition children={<View {...rest} />} />}
      />
    </Suspense>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const View = (props: OrdersManager & { locations: CustomerShipTo[]; finalAssortments: CustomerAssortmentArray[] }) => {
  return (
    <Container>
      {/* TODO: Inline OrderCapture in View, we don't need a separate component for this */}
      <OrderCapture {...props} />
    </Container>
  );
};
