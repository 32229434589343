import { Defect } from '../error/Defect';
import { Nullable } from '../type-utils/Nullable';
import { toggleArrayValue } from '../utils/array';
import { equals } from './equals';

export enum FilterType {
  Single = 'Single',
  Multi = 'Multi',
  PriceRange = 'PriceRange'
}

type FilterValueMapping = {
  [FilterType.Single]: unknown;
  [FilterType.Multi]: ReadonlyArray<unknown>;
  [FilterType.PriceRange]: { type: string; range?: readonly [start: number, end: number] };
};

export type ProductFilterId = string;

export interface Filter<T extends FilterType = FilterType> {
  readonly id: ProductFilterId;
  readonly type: T;
  readonly value: Nullable<FilterValueMapping[T]>;
  clear(): this;
  set(value: this['value']): this;
  equals(other: AnyFilter): boolean;
}

export const Filter = <T extends FilterType>(
  id: ProductFilterId,
  type: T,
  value: Filter<T>['value'] = null
): Filter<T> => ({
  id,
  type,
  value,

  clear() {
    if (value === null) return this;
    return Filter(id, type, null);
  },

  set(newValue) {
    if (type === FilterType.Single) return Filter(id, type, value === newValue ? null : newValue);

    if (type === FilterType.PriceRange) return Filter(id, type, value ? Object.assign({}, value, newValue) : newValue);

    if (type === FilterType.Multi) {
      if (!value) return Filter(id, type, newValue);
      if (!Array.isArray(value) || !Array.isArray(newValue)) {
        throw new Defect(`Expected array value in ${type} filter`);
      }
      const temp = toggleArrayValue(newValue, value);
      if (!temp.length) return Filter(id, type, null);
      return Filter(id, type, temp as typeof value);
    }

    throw new Defect(`Filter type not yet implemented: ${type}`);
  },

  equals(other) {
    const haveSameIds = equals(id, other.id);
    const haveSameTypes = equals(type, other.type);

    if (value == null && other.value == null) return haveSameIds && haveSameTypes;

    const haveSameValues = equalsSorted(value, other.value);
    return haveSameIds && haveSameTypes && haveSameValues;
  }
});

const equalsSorted = (x: unknown, y: unknown): boolean => {
  if (Array.isArray(x) && Array.isArray(y)) return equals([...x].sort(), [...y].sort());
  return equals(x, y);
};

export type AnyFilter = Filter<FilterType.Single> | Filter<FilterType.Multi> | Filter<FilterType.PriceRange>;

export type FiltersSchema = ReadonlyArray<AnyFilter>;
