import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { type Order, type OrdersManager, OrderStatus } from 'services';

import { once } from '@yourxx/support';
import type { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

import { useCustomersData } from '../CustomersDataProvider';
import { OrdersContext } from './OrdersContext';
import type { OrderDetails, OrderSizing, OrderSummary } from './types';

export const OrdersProvider = ({
  brand,
  customerId,
  season,
  children
}: {
  brand: string;
  customerId: string;
  season: string;
  children: ReactNode;
}) => {
  const { assortmentsFor } = useCustomersData();

  const customerData = useMemo(() => assortmentsFor(customerId), [assortmentsFor, customerId]);

  // TODO: Placeholder utilities. Delegate to service and use service-provided cached data
  const customerName = useMemo(
    () => customerData?.customerName ?? customerId.replace(/-+/g, ' ').toUpperCase(),
    [customerData?.customerName, customerId]
  );

  const finalAssortments = useMemo(() => {
    if (!(season in (customerData?.assortments ?? {}))) return [];
    return customerData?.assortments?.[season].filter(a => a.assortmentType === 'FINAL') ?? [];
  }, [customerData?.assortments, season]);

  // TODO: remove dupes on the BE
  const locations = useMemo(
    () => Object.values(customerData?.locations?.shipTo ?? {}),
    [customerData?.locations?.shipTo]
  );

  const ordersManager = useOrdersManager();

  // TODO: Generating mock data (remove eventually).
  useEffect(() => {
    if (ordersManager.orders.length) return;
    if (finalAssortments.length) ordersManager.seedMockOrdersFrom(finalAssortments, locations);
  }, [finalAssortments, locations, ordersManager]);

  const loadCustomerOrders = useMemo(
    () =>
      once(async (): Promise<void> => {
        await wait(500);
      }),
    []
  );

  const loadOrderDetails = useMemo(
    () =>
      once(
        (orderId: string) =>
          new Promise<OrderDetails>(res =>
            setTimeout(
              () =>
                res({
                  brand,
                  customerId,
                  customerName,
                  orderId,
                  details: 'Some order details'
                }),
              500
            )
          )
      ),
    [brand, customerId, customerName]
  );

  const loadOrderSummary = useMemo(
    () =>
      once(
        (orderId: string) =>
          new Promise<OrderSummary>(res =>
            setTimeout(
              () =>
                res({
                  brand,
                  customerId,
                  customerName,
                  orderId,
                  summary: 'Some order summary'
                }),
              500
            )
          )
      ),
    [brand, customerId, customerName]
  );

  const loadOrderSizing = useMemo(
    () =>
      (() => {
        const cache: Record<string, Promise<OrderSizing>> = {};

        return (orderId: string, pc9: string) => {
          const cacheKey = `${orderId}-${pc9}`;

          if (!(cacheKey in cache))
            cache[cacheKey] = new Promise<OrderSizing>(res =>
              setTimeout(
                () =>
                  res({
                    brand,
                    customerId,
                    customerName,
                    orderId,
                    pc9,
                    sizing: 'Some order sizing'
                  }),
                250
              )
            ).then(data => {
              // throw new Error('Some nasty error.');
              return data;
            });

          return cache[cacheKey];
        };
      })(),
    [brand, customerId, customerName]
  );

  return (
    <OrdersContext.Provider
      value={{
        brand,
        customerId,
        customerName,
        finalAssortments,
        locations,
        loadCustomerOrders,
        loadOrderDetails,
        loadOrderSummary,
        loadOrderSizing,
        ...ordersManager
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

const useOrdersManager = (): OrdersManager & {
  seedMockOrdersFrom(finalAssortments: CustomerAssortmentArray[], locations: CustomerShipTo[]): void;
} => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const createOrder = useCallback(async (order: Order, orderPerLocation?: boolean) => {
    setIsLoading(true);
    await wait(1000);

    const createOrderForLocation = (location: CustomerShipTo) => ({
      ...randomOrder([], [], {
        ...order,
        created: new Date().toISOString().split('T')[0],
        locations: [location]
      })
    });
    const createOrder = () => ({
      ...randomOrder([], [], {
        ...order,
        created: new Date().toISOString().split('T')[0],
        locations: orderPerLocation ? [] : order.locations
      })
    });

    const ordersToCreate = orderPerLocation
      ? order.locations.length > 1
        ? order.locations.map(createOrderForLocation)
        : [createOrderForLocation(order.locations[0])]
      : [createOrder()];

    setOrders(prev => [...prev, ...ordersToCreate]);
    setIsLoading(false);
  }, []);

  const editOrder = useCallback(async (order: Order) => {
    setIsLoading(true);
    await wait(1000);
    setOrders(prev =>
      prev.map(prev =>
        prev.orderId === order.orderId
          ? {
              ...prev,
              finalAssortment: order.finalAssortment,
              orderName: order.orderName,
              locations: order.locations
            }
          : prev
      )
    );
    setIsLoading(false);
  }, []);

  const deleteOrders = useCallback(async (orders: Order[]) => {
    setIsLoading(true);
    await wait(1000);
    setOrders(prev => prev.filter(order => !orders.some(o => o.orderId === order.orderId)));
    setIsLoading(false);
  }, []);

  const placeOrders = useCallback(async (orders: Order[]) => {
    console.log('ordering ' + orders.map(o => o.orderName));
    await wait(1000);
  }, []);

  const seedMockOrdersFrom = useCallback((finalAssortments: CustomerAssortmentArray[], locations: CustomerShipTo[]) => {
    setOrders(Array.from({ length: 20 }, () => randomOrder(finalAssortments, locations)));
  }, []);

  return {
    isLoading,
    orders,
    createOrder,
    editOrder,
    deleteOrders,
    placeOrders,
    // TODO: Remove after we have real data to work with.
    seedMockOrdersFrom
  };
};

const wait = (amountMs: number) => new Promise(res => setTimeout(res, amountMs));
const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
const randomOrder = (
  finalAssortments: CustomerAssortmentArray[],
  locations: CustomerShipTo[],
  orderData?: Partial<Order>
): Order => ({
  orderId: `${randomBetween(1000, 9999)}`,
  finalAssortment: orderData?.finalAssortment ?? finalAssortments[randomBetween(0, finalAssortments.length - 1)],
  locations: orderData?.locations ?? locations?.slice(0, randomBetween(1, 10)) ?? [],
  orderName: orderData?.orderName ?? `000000${randomBetween(1, 100).toString().padStart(2, '0')}`,
  created: orderData?.created ?? `2024-${randomBetween(1, 10)}-${randomBetween(1, 30)}`,
  products: orderData?.products ?? randomBetween(100, 1000),
  units: orderData?.units ?? randomBetween(10, 100),
  price: orderData?.price ?? randomBetween(10000, 50000),
  currency: orderData?.currency ?? 'GBP',
  status: orderData?.status ?? Math.random() < 0.5 ? OrderStatus.InProgress : OrderStatus.Submitted
});
