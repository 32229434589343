import { Manifest } from 'utils';
import { assign, createMachine } from 'xstate';

import { Defect, Nullable } from '@yourxx/support';
import { equals } from '@yourxx/support';
import {
  ApiGetProductOut,
  GetCustomerAssortmentResponse,
  GetCustomerListResponse,
  ProductListData,
  UIProduct
} from '@yourxx/types';

export enum State {
  Idle = 'Idle',
  Loading = 'Loading',
  LoadingFailed = 'LoadingFailed',
  CheckQueue = 'CheckQueue'
}

export enum Action {
  Load = 'Load',
  Retry = 'Retry',
  ClearCache = 'ClearCache',
  Cancel = 'Cancel'
}

export type LoadCustomersCommand = { type: 'loadCustomers' };
export type LoadAssortmentsCommand = { type: 'loadAssortments'; customerId: string; traceId?: string };
export type LoadProductsCommand = { type: 'loadProducts'; assortmentOrLineId: string; tag: string; traceId?: string };

type Command = LoadCustomersCommand | LoadAssortmentsCommand | LoadProductsCommand;
export type Event =
  | { type: Action.Load; command: Command }
  | { type: Action.Retry }
  | { type: Action.Cancel }
  | { type: Action.ClearCache; commands: { id: string; tag: string }[] };

export interface Context {
  error: Nullable<Error>;
  pendingCommand: Nullable<Command>;
  queue: Command[];
  customers: Nullable<GetCustomerListResponse[]>;
  assortmentsByCustomerId: Record<string, GetCustomerAssortmentResponse>;
  productsByTag: Record<string, Record<string, ApiGetProductOut<UIProduct<ProductListData>>>>;
  manifest: Manifest;
}

type Services = {
  loadCustomers: { data: GetCustomerListResponse[] };
  loadAssortments: { data: { customerId: string; assortments: GetCustomerAssortmentResponse } };
  loadProducts: { data: { assortmentOrLineId: string; products: ApiGetProductOut<UIProduct<ProductListData>> } };
};

const EMPTY_CONTEXT: Context = {
  error: null,
  pendingCommand: null,
  queue: [],
  customers: null,
  assortmentsByCustomerId: {},
  productsByTag: {},
  manifest: Manifest()
};

export const customersStateMachine = createMachine(
  {
    id: 'customers-state-machine',
    predictableActionArguments: true,
    schema: {
      services: {} as Services,
      context: {} as Context,
      events: {} as Event
    },
    context: EMPTY_CONTEXT,
    initial: State.Idle,
    states: {
      [State.Idle]: {
        entry: assign({ error: () => null }),
        on: {
          [Action.Load]: {
            cond: (context, event) => isNotAlreadyCached(context, event.command),
            target: State.CheckQueue,
            actions: assign({ queue: (context, event) => context.queue.concat(event.command) })
          },
          [Action.ClearCache]: { actions: 'clearCache' }
        }
      },
      [State.CheckQueue]: {
        on: {
          [Action.ClearCache]: { actions: 'clearCache' }
        },
        always: [
          {
            cond: context => !context.queue.length,
            target: State.Idle,
            actions: assign({ pendingCommand: null })
          },
          {
            cond: context => context.queue.length === 1 && isNotAlreadyCached(context, context.queue[0]),
            target: State.Loading,
            actions: assign({ pendingCommand: context => context.queue[0], queue: [] })
          },
          {
            cond: context => context.queue.length === 1 && !isNotAlreadyCached(context, context.queue[0]),
            target: State.Idle,
            actions: assign({ pendingCommand: null, queue: [] })
          },
          {
            cond: context => context.queue.length > 1,
            target: State.Loading,
            actions: assign(context => {
              let queue: Command[] = context.queue;

              while (queue.length) {
                const [nextCommand, ...rest] = queue;
                queue = rest;

                if (queue.length && !isNotAlreadyCached(context, nextCommand)) continue;

                return {
                  ...context,
                  pendingCommand: nextCommand,
                  queue
                };
              }

              return context;
            })
          }
        ]
      },
      [State.Loading]: {
        on: {
          [Action.ClearCache]: { actions: 'clearCache' },
          [Action.Load]: {
            cond: (context, event) =>
              !context.queue.find(command => JSON.stringify(command) === JSON.stringify(event.command)),

            actions: assign({
              queue: (context, event) => context.queue.concat(event.command)
            })
          }
        },
        invoke: {
          src: 'load',
          onError: {
            target: State.LoadingFailed,
            actions: assign({
              error: (_context, event) => event.data
            })
          },
          onDone: {
            target: State.CheckQueue,
            actions: [
              assign({
                customers: (context, event) =>
                  context.pendingCommand?.type === 'loadCustomers' ? event.data : context.customers,

                assortmentsByCustomerId: (context, event) =>
                  context.pendingCommand?.type === 'loadAssortments'
                    ? { ...context.assortmentsByCustomerId, [context.pendingCommand.customerId]: event.data }
                    : context.assortmentsByCustomerId,

                productsByTag: (context, event) =>
                  context.pendingCommand?.type === 'loadProducts'
                    ? {
                        ...context.productsByTag,
                        [context.pendingCommand.tag]: {
                          ...context.productsByTag[context.pendingCommand.tag],
                          [context.pendingCommand.assortmentOrLineId]: event.data
                        }
                      }
                    : context.productsByTag,

                manifest: context => {
                  switch (context.pendingCommand?.type) {
                    case 'loadProducts':
                      return context.manifest.remove(
                        context.pendingCommand.tag,
                        context.pendingCommand.assortmentOrLineId
                      );
                    case 'loadAssortments':
                      return context.manifest.remove('customerAssortments', context.pendingCommand.customerId);
                    default:
                      return context.manifest;
                  }
                }
              })
            ]
          }
        }
      },
      [State.LoadingFailed]: {
        on: {
          [Action.Retry]: State.Loading,
          [Action.Cancel]: {
            target: State.Idle,
            actions: [
              assign({
                queue: context =>
                  !context.pendingCommand ? context.queue : dedupe(context.pendingCommand, context.queue)
              }),
              assign({ pendingCommand: null })
            ]
          }
        }
      }
    }
  },
  {
    actions: {
      clearCache: assign({
        manifest: (context, event) => {
          if (event.type !== Action.ClearCache)
            throw new Defect(`Expected ${Action.ClearCache} command, instead received: ${event.type}`);

          return event.commands.reduce(
            (manifest, command) => manifest.scheduleForReload(command.tag, command.id),
            context.manifest
          );
        }
      })
    },
    guards: {
      checkCache: (context, event) => {
        if (!('command' in event)) throw new Defect('Expected command in event.');
        return isNotAlreadyCached(context, event.command);
      }
    }
  }
);

// TODO: Invert name and conditions to make it more readable (avoid double negation)
const isNotAlreadyCached = (context: Context, command: Command) => {
  switch (command.type) {
    case 'loadCustomers':
      return !context.customers;
    case 'loadAssortments': {
      if (context.manifest.isScheduledForReload('customerAssortments', command.customerId)) return true;

      return !(command.customerId in context.assortmentsByCustomerId);
    }
    case 'loadProducts': {
      if (context.manifest.isScheduledForReload(command.tag, command.assortmentOrLineId)) return true;

      if (!(command.tag in context.productsByTag)) return true;
      return !(command.assortmentOrLineId in context.productsByTag[command.tag]);
    }
  }
};

export class FailedLoadingAssortments extends Error {
  constructor(public readonly customerId: string) {
    super();
  }
}

export class FailedLoadingProducts extends Error {
  constructor(
    public readonly assortmentOrLineId: string,
    public readonly tag: string,
    public readonly causedBy: Error
  ) {
    super();
  }
}

const dedupe = (c: Command, queue: readonly Command[]): Command[] => queue.filter(qc => !equals(c, qc));
