import { useCallback, useMemo, useState } from 'react';
import { type AssortmentAuditTrailEvent, AuditTrailService, OrderAuditTrailEvent } from 'services';
import { AuditTrailEvent } from 'services';

import { Nullable } from '@yourxx/support';

type EventFor<T extends 'assortment' | 'order'> = {
  assortment: AssortmentAuditTrailEvent;
  order: OrderAuditTrailEvent;
}[T];

interface UseAuditTrailReturn<E extends AuditTrailEvent> {
  isLoading: boolean;
  eventsFor(entityId: string): Nullable<ReadonlyArray<E>>;
  unseenEventsCount(entityId: string): number;
  clearCache(entityId: string): void;
}

// TODO: Move
export const useAuditTrail = <T extends 'assortment' | 'order', E extends EventFor<T>>(
  entityType: T,
  service: AuditTrailService<E>
): UseAuditTrailReturn<E> => {
  const [data, setData] = useState<Record<string, E[] | Promise<E[]>>>({});
  const isLoading = useMemo(() => Object.values(data).some(value => value instanceof Promise), [data]);

  const eventsFor = useCallback(
    (entityId: string) => {
      if (!data[entityId] || service.isScheduledForReload({ entityType, entityId })) {
        setData(prev => ({
          ...prev,
          [entityId]: service
            .loadEvents({ entityType, entityId: entityId })
            .then(events => {
              setData(prev => ({ ...prev, [entityId]: events }));
              return events;
            })
            .catch(() => {
              setData(prev => {
                const newData = { ...prev };
                delete newData[entityId];
                return newData;
              });

              return [];
            })
        }));

        return null;
      }

      const events = data[entityId];
      if (events instanceof Promise) return null;
      return events;
    },
    [data, entityType, service]
  );

  const unseenEventsCount = useCallback(
    (entityId: string) => {
      const events = data[entityId];
      if (!events || events instanceof Promise) return 0;
      return events.reduce((unseenCount, event) => unseenCount + (event.hasBeenSeen ? 0 : 1), 0);
    },
    [data]
  );

  const clearCache = useCallback(
    (entityId: string) => service.clearCache({ entityType, entityId: entityId }),
    [entityType, service]
  );

  return {
    isLoading,
    eventsFor,
    unseenEventsCount,
    clearCache
  };
};
