import { createContext } from 'react';
import type { OrdersManager } from 'services';

import type { Nullable } from '@yourxx/support';
import type { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

import type { OrderDetails, OrderSizing, OrderSummary } from './types';

interface OrdersContextValue extends OrdersManager {
  brand: string;
  customerId: string;
  customerName: string;
  finalAssortments: CustomerAssortmentArray[];
  locations: CustomerShipTo[];
  loadCustomerOrders(season: string): Promise<void>;
  loadOrderDetails(orderId: string): Promise<OrderDetails>;
  loadOrderSummary(orderId: string): Promise<OrderSummary>;
  loadOrderSizing(orderId: string, pc9: string): Promise<OrderSizing>;
}

export const OrdersContext = createContext<Nullable<OrdersContextValue>>(null);
