import { Footer, IAM } from 'components';
import { Button } from 'components/Button';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { type Order, OrderStatus } from 'services';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const OrdersButton = styled(Button)`
  padding: ${themed('spacing.m')} !important;
`;
const SummaryButton = styled(OrdersButton)`
  border: solid ${rem(1)} ${themed('color.red')};
`;
const DeleteButton = styled(OrdersButton)`
  color: ${themed('color.red')};
`;

export const StatusBreakdown = ({ orders }: { orders: Order[] }) => {
  const [str] = useLocalisation();

  const statuses = useMemo(
    () =>
      orders.reduce(
        (accumulator, order) => {
          if (order.status) {
            accumulator[order.status] = (accumulator[order.status] || 0) + 1;
          }
          return accumulator;
        },
        {} as Record<OrderStatus, number>
      ),
    [orders]
  );

  return (
    <>
      {Object.entries(statuses).map(([key, count], i, arr) => {
        const divider = i === arr.length - 1 ? '' : i === arr.length - 2 ? ` ${str('general.and')}` : ',';

        return (
          <span key={key}>
            {count} {str(`Landing.order.status.${key as OrderStatus}`)}
            {divider}
          </span>
        );
      })}
    </>
  );
};

export const OrderFooter = ({
  orders,
  selectedOrders,
  onDeselectAll,
  viewSummary,
  placeOrders,
  deleteOrders
}: {
  orders: Order[];
  selectedOrders: number;
  onDeselectAll: VoidFunction;
  viewSummary?: VoidFunction;
  placeOrders?: VoidFunction;
  deleteOrders?: VoidFunction;
}) => {
  const [str] = useLocalisation();

  return (
    <Footer
      items={orders}
      selectedItems={selectedOrders}
      onDeselectAll={onDeselectAll}
      breakdown={<StatusBreakdown orders={orders} />}
      leftActionButtons={
        <IAM action={`orders.delete`}>
          {deleteOrders && (
            <DeleteButton onClick={deleteOrders}>
              {str('Landing.order.footer.deleteOrders', { count: selectedOrders })}
            </DeleteButton>
          )}
        </IAM>
      }
      rightActionButtons={
        selectedOrders > 0 && (
          <>
            {viewSummary && (
              <SummaryButton onClick={viewSummary}>{str('Landing.order.footer.viewSummary')}</SummaryButton>
            )}
            <IAM action={`orders.submit`}>
              {placeOrders && (
                <OrdersButton variant="primary" onClick={placeOrders}>
                  {str('Landing.order.footer.placeOrders', { count: selectedOrders })}
                </OrdersButton>
              )}
            </IAM>
          </>
        )
      }
    />
  );
};
