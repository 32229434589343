import type { GetAuditTrailResponse } from '@yourxx/types';

import { AuditTrailEvent } from './AuditTrailEvent';
import { type AuditTrailService } from './AuditTrailService';

type Payload = GetAuditTrailResponse extends (infer E)[] ? E : never;

export class AssortmentAuditTrailEvent extends AuditTrailEvent {
  static of(payload: Payload, service: AuditTrailService) {
    return new AssortmentAuditTrailEvent(payload, service);
  }

  get entityType() {
    return this.payload.item_type;
  }

  get entityId() {
    return this.payload.item_id;
  }

  get createdAt() {
    return this.payload.created_at;
  }

  private constructor(
    public readonly payload: Readonly<Payload>,
    service: AuditTrailService
  ) {
    super(service);
  }
}
