import type { ReactNode } from 'react';
import { useIAM } from 'utils';

/**
 * Configurable IAM Route guard.
 * This is meant to be used within `<Route />` components only.
 * Use `<IAM />` for conditional rendering within other components.
 *
 * @param by A single action the user must be able to pass
 * @param byAll Pass a list of actions the user must pass
 * @param byAny Pass a list of actions of which at least one the user must pass
 * @param at Action prefix to use when deriving from URL is less than ideal
 * @param children
 * @param fallback
 */
export const Guard = ({
  by,
  at: prefix,
  byAll,
  byAny,
  children,
  else: fallback = null
}: {
  at?: string;
  by?: string;
  byAll?: string[];
  byAny?: string[];
  children: ReactNode;
  else?: ReactNode;
}) => {
  const { canUse } = useIAM();

  if (!by && !byAll && !byAny) return children;

  const can = by ? canUse(by, prefix) : false;
  const canAll = byAll ? byAll.every(a => canUse(a, prefix)) : false;
  const canAny = byAny ? byAny.some(a => canUse(a, prefix)) : false;
  return can || canAll || canAny ? children : fallback;
};
