import { Settings } from 'assets/icons';
import { IAM } from 'components/IAM';
import { SlotId, Toolbar } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';

import { getAdminUrl } from '@yourxx/ui-utils/src/utils/appSwitching';

const SettingsIcon = styled(Settings)`
  &:not(#specificity-bump) path {
    stroke: none;
  }
`;

const Setup = () => {
  const [str] = useLocalisation();
  const { pathname } = useLocation();

  const adminUrl = useMemo(() => {
    return getAdminUrl(window.location.href);
    // FIXME: Linter says hook dependency here needs a revisit.
  }, [window.location.href]);

  useSlot(
    SlotId.HeaderTopToolbar,
    useMemo(
      () => (
        <Toolbar.Item
          key={pathname}
          icon={<SettingsIcon />}
          external
          onClick={() => {
            return (window.location.href = adminUrl);
          }}
          hint={{ text: str('Header.admin'), position: 'bottom' }}
        />
      ),
      [adminUrl, pathname, str]
    ),
    1
  );

  return null;
};

export const AdminPanelAccessSetup = () => {
  return (
    <IAM action={'viewAdmin'} prefix={'header'}>
      <Setup />
    </IAM>
  );
};
