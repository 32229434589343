import { ToCase } from '@yourxx/types';

import { isArray, isDate, isObject, isString } from './helpers/is';
import { toCase } from './helpers/to';

type RequiredKeys<T> = Partial<keyof T> | string | number;

type TransformObjectParams<T> = {
  omitKeys?: string[];
  requiredKeys?: RequiredKeys<T>[];
  toCase?: ToCase;
  toISODateString?: boolean;
  removeEmptyString?: boolean;
};

const isRequiredKey = <T = any>(k: string, params?: TransformObjectParams<T>): boolean => {
  if (params && params.requiredKeys && params.requiredKeys.length > 0) {
    return params.requiredKeys.includes(k);
  }
  if (params && params.omitKeys && params.omitKeys.length > 0) {
    return !params.omitKeys.includes(k);
  }
  return true;
};

const isUndefined = (value: any): boolean => {
  if (isArray(value)) {
    return value?.length < 1;
  }
  if (isObject(value)) {
    return Object.keys(value).length < 1;
  }
  return value === null || value === undefined || value.length === 0;
};

const getNewValue = <T>(value: unknown, params?: TransformObjectParams<T>): T | unknown | string => {
  const toISODateString = params?.toISODateString ?? true;
  if (isDate(value) && toISODateString) {
    return value.toISOString();
  }
  if (isString(value) && params?.removeEmptyString === true) {
    return value.trim() === '' ? undefined : value;
  }
  if (isObject(value)) {
    return transformObject<T>(value, params);
  }
  return value;
};

const removeUndefinedFromArray = <T = any>(array: any[], params?: TransformObjectParams<T>): T => {
  const newArray: any = [];
  for (let i = 0; i < array.length; i++) {
    const value = array[i];
    const newValue = getNewValue(value, params);
    if (!isUndefined(newValue)) {
      newArray.push(newValue);
    }
  }
  return newArray;
};

const removeUndefinedKeysObject = <T = any>(object: any, params?: TransformObjectParams<T>): T => {
  const keys = Object.keys(object);
  const newObject: any = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = object[key];
    const newKey = params?.toCase ? toCase[params.toCase](key) : key;
    if (!isRequiredKey(newKey, params)) {
      continue;
    }
    const newValue = getNewValue(value, params);
    if (!isUndefined(newValue)) {
      newObject[newKey] = newValue;
    }
  }
  return newObject;
};

export const transformObject = <T = any>(obj: any, params?: TransformObjectParams<T>): T => {
  if (obj && isArray(obj)) {
    return removeUndefinedFromArray<T>(obj, params) as T;
  }
  if (obj && isObject(obj)) {
    return removeUndefinedKeysObject<T>(obj, params) as T;
  }
  return obj as T;
};

export const mergeAndDedupe = <T = any>(array1?: T[], array2?: T[]): T[] => {
  if (isArray(array1) && isArray(array2)) {
    return [...new Set([...array1, ...new Set(array2)])];
  }
  if (isArray(array1) && !isArray(array2)) {
    return [...new Set([...array1])];
  }
  if (!isArray(array1) && isArray(array2)) {
    return [...new Set([...array2])];
  }
  return array1 ?? array2 ?? [];
};

export const mergeArraysInNestedObjects = <T extends Partial<Record<keyof T, any>>>(object1: T, object2: T): T => {
  if (!object1 || typeof object1 !== 'object') {
    return object2;
  }
  if (!object2 || typeof object2 !== 'object') {
    return object1;
  }
  return (Object.keys(object2) as Array<keyof T>).reduce(
    (agg: T, key: keyof T) => {
      if (!object2[key]) {
        return agg;
      }
      if (isArray(object2[key])) {
        agg[key] = mergeAndDedupe(agg[key], object2[key]) as T[keyof T];
        return agg;
      }
      if (isObject(object2[key])) {
        agg[key] = mergeArraysInNestedObjects(agg[key], object2[key]);
        return agg;
      }
      agg[key] = object2[key];
      return agg;
    },
    { ...object1 }
  );
};
