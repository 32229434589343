import { Entity } from 'domain-events';
import type { UseCustomersReturn } from 'providers/CustomersDataProvider/useCustomers';
import { useCallback } from 'react';
import {
  AddedProductsToStory,
  type AuditTrailService,
  type EventBus,
  ProductsAdded,
  ProductsRemoved,
  ProductsUpdated
} from 'services';
import { useEventSubscriptions } from 'utils';

// TODO: Refactor to use CacheClearRequested event instead of directly calling the utility functions.
export const useHandleAssortmentUpdated = ({
  eventBus,
  auditTrailService,
  clearCache,
  loadProductsFor,
  loadAssortmentsFor
}: {
  eventBus: EventBus;
  auditTrailService: AuditTrailService;
} & Pick<UseCustomersReturn, 'clearCache' | 'loadProductsFor' | 'loadAssortmentsFor'>) => {
  const handleEvent = useCallback(
    (event: ProductsAdded | ProductsRemoved | ProductsUpdated | AddedProductsToStory) => {
      if (event.payload.context.entity !== Entity.Assortment && event.payload.context.entity !== Entity.FinalAssortment)
        return;

      // This will reload the assortments for the given customer id to get updated product counts.
      clearCache({ id: event.payload.context.customerId, tag: 'customerAssortments' });

      // This will reload the updated assortment's two products lists.
      clearCache(
        { id: event.payload.context.id, tag: 'assortment' },
        { id: event.payload.context.id, tag: 'droppedAndRemoved' },
        { id: event.payload.context.id, tag: 'parentLine' },
        { id: event.payload.context.id, tag: 'parentLineDroppedAndRemoved' }
      );

      // This will ensure the products for the source assortment will be refreshed in case the target assortment was
      // of type 'FINAL', so that filters can be rebuilt (e.g. "Not in Final Assortment").
      if (event instanceof ProductsAdded && event.payload.sourceAssortmentId)
        clearCache({ id: event.payload.sourceAssortmentId, tag: 'assortment' });

      // This will ensure products in the Audit Trail "Removed" tab will be refreshed.
      if (event instanceof ProductsRemoved) clearCache({ id: event.payload.context.id, tag: 'removedFromAssortment' });

      auditTrailService.clearCache({ entityType: 'assortment', entityId: event.payload.context.id });

      loadProductsFor(event.payload.context.id, 'assortment', event.traceId());
      loadAssortmentsFor(event.payload.context.customerId, event.traceId());
    },
    [auditTrailService, clearCache, loadAssortmentsFor, loadProductsFor]
  );

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(ProductsRemoved, handleEvent),
        eventBus.on(ProductsAdded, handleEvent),
        eventBus.on(ProductsUpdated, handleEvent),
        eventBus.on(AddedProductsToStory, handleEvent)
      ],
      [eventBus, handleEvent]
    )
  );
};
