import { SlotId } from 'pages';
import { useSlot } from 'providers';
import { useMemo } from 'react';

import { Toolbar, type ToolbarItemProps } from '../components';

/**
 * Wrap your `item` with a `useMemo` to avoid unnecessary re-rendering.
 */
export const useAddPageToolbarControl = (item: ToolbarItemProps, position = 0) => {
  useSlot(
    SlotId.PageToolbar,
    useMemo(() => <Toolbar.Item {...item} />, [item]),
    position
  );
};
