import { Defect } from '../error/Defect';
import { filterBy } from '../utils/array';
import { AnyFilter, type FiltersSchema, FilterType, type ProductFilterId } from './Filter';

const topLevelCategories = ['tops', 'bottoms', 'footwear', 'accessories'];

const filterIdToValueKey = (filterId: ProductFilterId): string => {
  switch (filterId) {
    case 'fit':
      return 'category2';
    case 'notInFinalAssortment':
      return 'inFinalAssortments';
    default:
      return filterId;
  }
};

export function normalizeFilterValues(value: string | string[]): string[] {
  if (typeof value === 'string') {
    return [value.toUpperCase().trim()];
  } else if (Array.isArray(value)) {
    return value.map(val => val.toUpperCase().trim());
  } else {
    return [];
  }
}

export const filtersToFilterFunction = (filters: FiltersSchema) => {
  const topLevelCategoryValues = Accumulator();
  const predicatesByProp: Record<string, (value: any) => boolean> = {};
  const filtersWithValues: AnyFilter[] = [];

  for (const filter of filters) {
    if (filter.value === null) {
      continue;
    }
    filtersWithValues.push(filter);

    if (
      topLevelCategories.includes(filter.id) &&
      (filter.type === FilterType.Single || filter.type === FilterType.Multi)
    ) {
      topLevelCategoryValues.add(filter.id, filter.value);
      continue;
    } else if (filter.id === 'notInFinalAssortment') {
      if (filter.type !== FilterType.Multi)
        throw new Defect(`Expected filter with id "${filter.id}" to be of type ${FilterType.Multi}`);

      predicatesByProp[filter.id] = (inIds: undefined | string[]) => {
        if (!inIds) return true;
        return Boolean(filter.value?.every(id => !inIds?.includes(String(id))));
      };

      continue;
    } else if (filter.id === 'mandatory') {
      predicatesByProp[filter.id] = (maybeMandatoryValue?: boolean | null) =>
        filter.value ? Boolean(maybeMandatoryValue) : !maybeMandatoryValue;
      continue;
    } else if (filter.id === 'replen') {
      predicatesByProp[filter.id] = (maybeReplenValue?: string | null) => {
        const maybeReplenValueAsBoolean = maybeReplenValue === 'TRUE';
        return filter.value ? maybeReplenValueAsBoolean : !maybeReplenValueAsBoolean;
      };
      continue;
    } else if (filter.id === 'segmentation' || filter.id === 'story' || filter.id === 'distribution') {
      /* Was getting unexpected results for story and for segmentation when matching on FilterType.Multi
      // Therefore added in this normalisation code for types, upper/lower case, etc.
      // which in turn means we can use strict: true argument. Using strict: true solves this issue
      */

      predicatesByProp[filter.id] = itemValue => {
        if (!Array.isArray(itemValue)) {
          return false; // itemValue needs to be an array, otherwise exit
        }

        // Attempt to normalise itemValue in stories/segmentaion/distribution
        const normalizedItemValues = itemValue.map(value => value.toUpperCase().trim());
        const filterValuesNormalized = normalizeFilterValues(filter.value as string | string[]);

        return filterValuesNormalized.some(filterVal => normalizedItemValues.includes(filterVal));
      };

      continue;
    } else if (filter.id === 'storeGrades') {
      predicatesByProp[filter.id] = (maybeValue?: string[]) => {
        return Array.isArray(filter.value)
          ? Boolean(filter.value?.some(v => maybeValue?.includes(v)))
          : (maybeValue?.indexOf(filter.value as string) ?? -1) > -1;
      };
      continue;
    } else if (filter.id === 'ranking') {
      predicatesByProp[filter.id] = (rankValue = 0) => {
        if (filter.type === FilterType.Multi) return filter.value!.includes(rankValue);
        else return filter.value === rankValue;
      };
      continue;
    }

    switch (filter.type) {
      case FilterType.Single: {
        predicatesByProp[filter.id] = filterBy.string({ toBe: filter.value as string | number, strict: true });
        break;
      }
      case FilterType.Multi: {
        predicatesByProp[filter.id] = filterBy.string({ toBe: filter.value as (string | number)[], strict: true });
        break;
      }
      case FilterType.PriceRange:
        predicatesByProp[filter.value.type] = (value: number) => {
          if (!filter.value?.range) return true;
          return filter.value.range[0] <= value && value <= filter.value.range[1];
        };
    }
  }

  return <T extends Record<string, any>>(items: ReadonlyArray<T>): T[] => {
    if (!filtersWithValues.length) return items as T[];

    return items.filter(item => {
      const matchesAllOtherFilters = Object.keys(predicatesByProp).length
        ? Object.entries(predicatesByProp).every(([filterId, predFn]) =>
            predFn(item[filterIdToValueKey(filterId) as keyof typeof item])
          )
        : true;

      const matchesAnyTopLevelCategoryFilter = Object.keys(topLevelCategoryValues.value).length
        ? Object.entries(topLevelCategoryValues.value).some(([key, values]) => {
            return item.category0 === key.toUpperCase() && values.includes(item.category1);
          })
        : true;

      return matchesAllOtherFilters && matchesAnyTopLevelCategoryFilter;
    });
  };
};

const Accumulator = () => {
  const accum: Record<string, unknown[]> = {};

  return {
    get value() {
      return accum;
    },

    add<T>(key: string, value: T | T[]) {
      if (!(key in accum)) {
        accum[key] = [];
      }
      if (Array.isArray(value)) {
        accum[key].push(...value);
      } else {
        accum[key].push(value);
      }
    }
  };
};
