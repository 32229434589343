import { MotionOutlet } from 'components/MotionOutlet';
import { SlotId } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { themed } from 'utils';

import { Footer } from './Footer';
import { PageHeader } from './PageHeader';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  padding: 0 ${themed('spacing.xl')};
  background-color: ${themed('color.white')};
`;

const SidePanelsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;

export const CommonLayout = () => (
  <>
    <PageHeader />
    <Container>
      <MotionOutlet />
      <Slot id={SlotId.SidePanels} Container={SidePanelsContainer} dontRenderEmpty />
    </Container>
    <Footer />
    <Slot id={SlotId.Modals} />
  </>
);
