import { useLocalisation, useSlot } from 'providers';
import { useMemo } from 'react';

import { useSidePanel } from '../../Products';
import { SlotId } from '../../SlotId';
import { useOrderAuditTrailUI } from '../hooks/useOrderAuditTrailUI';
import { useAddOrderToolbarControl } from '../OrderLayout';

export const SetupOrderToolbar = ({ orderId }: { orderId: string }) => {
  const [str] = useLocalisation();
  const sidePanel = useSidePanel<'auditTrail'>();

  const showUnseenCount = true;
  const { ui, unseenEventsCount, isLoading } = useOrderAuditTrailUI({
    orderId,
    sidePanel,
    loadDataOnRender: showUnseenCount
  });

  useSlot(SlotId.SidePanels, ui);
  useAddOrderToolbarControl(
    useMemo(
      () => ({
        badgeContent: showUnseenCount && unseenEventsCount ? unseenEventsCount.toString() : undefined,
        icon: undefined,
        // icon: <Unit />,
        isActive: sidePanel.id === 'auditTrail',
        isLoading,
        hint: isLoading ? str('Order.auditTrail.buttonLabel') : undefined,
        label: str('Order.auditTrail.buttonLabel').toUpperCase(),
        onClick: () => sidePanel.set('auditTrail'),
        position: -Infinity
      }),
      [isLoading, showUnseenCount, sidePanel, str, unseenEventsCount]
    ),
    -Infinity
  );

  return null;
};
