import { useEffect, useState } from 'react';
import { type AuditTrailService, OrderAuditTrailEvent, useServices } from 'services';
import { useUserId } from 'utils';

import { useAuditTrail, type UseSidePanelReturn } from '../../Products';
import { AuditTrailPanel } from '../../Products/AssortmentFinalization';

export const useOrderAuditTrailUI = ({
  orderId,
  sidePanel,
  loadDataOnRender = true
}: {
  orderId: string;
  sidePanel: UseSidePanelReturn<'auditTrail'>;
  loadDataOnRender?: boolean;
}) => {
  const currentUserId = useUserId();
  const { isLoading, eventsFor, clearCache, unseenEventsCount } = useAuditTrail(
    'order',
    useServices().auditTrailService as AuditTrailService<OrderAuditTrailEvent>
  );
  const [shouldLoadData, setShouldLoadData] = useState(loadDataOnRender);
  const canLoadOrderAuditTrail = true; // TODO: Fill in permission check
  const events = canLoadOrderAuditTrail && shouldLoadData ? eventsFor(orderId) : null;

  useEffect(() => {
    if (sidePanel.id === 'auditTrail') setShouldLoadData(true);
  }, [sidePanel.id]);

  return {
    isLoading,
    unseenEventsCount: unseenEventsCount(orderId),
    ui: (
      <AuditTrailPanel
        isLoading={isLoading}
        isOpen={sidePanel.id === 'auditTrail'}
        onClose={() => sidePanel.set('auditTrail')}
        events={events}
        onRefresh={() => {
          clearCache(orderId);
          eventsFor(orderId);
        }}
        render={renderEvent(currentUserId.current)}
        // TODO: Add removed products tab, but what do we do when we "add back"?
        // additionalTabs={[]}
      />
    )
  };
};

const renderEvent = (currentUserId: string) => (event: OrderAuditTrailEvent) => {
  // TODO: Render order events once we know shape from the API.
  return (
    <div>
      <span>UserId: {currentUserId}</span>
      <h4>{event.payload.name}</h4>
    </div>
  );
};
