import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { toggleArrayValue } from '@yourxx/support';

interface UseSelectMonthsProps<Month = string> {
  months?: readonly Month[];
}

interface UseSelectMonthsReturn<Month = string> {
  selectedMonths: Month[];
  selectMonth: (monthId: Month) => void;
}

const key = 'months' as const;
const delimiter = '_'; // Encoded in the URL remains unchanged.
const encode = <T extends string>(months: T[]) => months.join(delimiter);
const decode = (encoded: string) => encoded.split(delimiter).filter(Boolean);

export const useSelectMonths = <M extends string>({
  months = []
}: UseSelectMonthsProps<M> = {}): UseSelectMonthsReturn<M> => {
  const [params, setParams] = useSearchParams();
  const value = params.get(key) ?? '';
  const state = useMemo(() => decode(value) as M[], [value]);

  const setState = useCallback(
    (...monthIds: M[]) => {
      setParams(prev => {
        const updated = new URLSearchParams(prev);
        const parsed = decode(updated.get(key) ?? '') as M[];
        const encoded = encode<M>(toggleArrayValue(monthIds, parsed));
        if (!encoded) updated.delete(key);
        else updated.set(key, encoded);
        return updated;
      });
    },
    [setParams]
  );

  useEffect(() => {
    if (!state.length && months.length) {
      setState(...months);
    }
  }, [months, setState, state.length]);

  return {
    selectedMonths: state,
    selectMonth: setState
  };
};
