import { AssortmentAuditTrailEvent } from 'services';

import { Event } from '@yourxx/types';

import type { RemovedProduct } from './types';

const ALLOWED_EVENTS = [Event.REMOVE_PC9S, Event.DROP_PC9S];

type EventProduct = { pc9: string; name: string; url: string; status: string };

export const removedProductsFromEvents = <P extends EventProduct>(
  events: ReadonlyArray<AssortmentAuditTrailEvent>,
  products: ReadonlyArray<P>,
  onAddBack: (product: P) => Promise<void>
) => {
  const productsByPC9 = products.reduce<Record<string, P>>((accum, product) => {
    accum[product.pc9] = product;
    return accum;
  }, {});

  const removed: RemovedProduct[] = [];

  for (const event of events) {
    if (!ALLOWED_EVENTS.includes(event.payload.name)) continue;
    for (const pc9 of event.payload.payload.pc9s ?? []) {
      const product = productsByPC9[pc9];
      if (!product) continue;

      removed.push({
        id: `${event.payload.event_id}|${pc9}`,
        date: event.createdAt,
        pc9,
        title: product.name,
        reason: '',
        image: product.url,
        status: product.status,
        addBack: () => onAddBack(product)
      });
    }
  }

  return removed;
};
