import { toSorted } from '@yourxx/support';

export const multiSort = <T, K extends keyof T>(
  array: readonly T[],
  { by, dir, fallbacks = {} }: { by: K[]; dir: -1 | 1; fallbacks?: Partial<T> }
): T[] => {
  const compare = (a: T, b: T, keys: K[] = by): number => {
    const [key, ...rest] = keys;
    const x = a[key] ?? fallbacks[key];
    const y = b[key] ?? fallbacks[key];
    return (x === y ? (rest.length ? compare(a, b, rest) * dir : 0) : x > y ? 1 : -1) * dir;
  };

  if (Array.isArray(array)) {
    return toSorted(array, compare);
  }
  return [];
};
