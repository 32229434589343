import { Guard } from 'components/Guard';
import { OrdersProvider, useCustomersData } from 'providers';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CustomerOrdersIndex } from '../../Orders';
import { AssortmentProductsRoute } from '../AssortmentProducts';
import { AssortmentsIndex as Index } from '../AssortmentsIndex';
import { customerRoute } from '../customer-route-mapping';
import { CustomerLayout } from '../CustomerLayout';

export const Customer = (props: { brand: string; customerId: string; season: string }) => {
  const { customerId } = props;
  const { loadAssortmentsFor } = useCustomersData();

  useEffect(() => {
    loadAssortmentsFor(customerId);
  }, [customerId, loadAssortmentsFor]);

  return (
    <OrdersProvider {...props}>
      <Routes>
        <Route Component={CustomerLayout}>
          <Route index element={<Navigate to="assortments" replace />} />
          <Route path={`${customerRoute.active}/insights?`} element={<Index {...props} type="active" />} />
          <Route path={`${customerRoute.archived}/insights?`} element={<Index {...props} type="archived" />} />
          <Route path={`${customerRoute.final}/insights?`} element={<Index {...props} type="final" />} />

          <Route path={`${customerRoute.active}/:assortmentId/*`} Component={AssortmentProductsRoute} />
          <Route path={`${customerRoute.archived}/:assortmentId/*`} Component={AssortmentProductsRoute} />
          <Route path={`${customerRoute.final}/:assortmentId/*`} Component={AssortmentProductsRoute} />

          <Route
            path={`${customerRoute.orders}/*`}
            element={
              <Guard by="orders.view" at="customers" else={<Navigate to="../" replace />}>
                <CustomerOrdersIndex {...props} />
              </Guard>
            }
          />
        </Route>
      </Routes>
    </OrdersProvider>
  );
};
