import { CustomerAssortmentArray, CustomerShipTo } from '@yourxx/types';

export enum OrderStatus {
  InProgress = 'InProgress',
  Submitted = 'Submitted'
}

export interface Order {
  finalAssortment: CustomerAssortmentArray;
  locations: CustomerShipTo[];
  orderId: string;
  orderName: string;
  created: string;
  products: number;
  units: number;
  price: number;
  currency: string;
  status: OrderStatus;
}

export interface OrdersManager {
  isLoading: boolean;
  orders: Order[];
  createOrder: (order: Order, orderPerLocation?: boolean) => Promise<void>;
  editOrder: (order: Order) => Promise<void>;
  deleteOrders: (orders: Order[]) => Promise<void>;
  placeOrders: (orders: Order[]) => Promise<void>;
}
