import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { useSlots } from './useSlots';

// When no custom container is passed to the Slot, we're just rendering a good ol' DIV element.
const DefaultContainer = styled.div``;

// This is not part of the App's design, no need to inherit App styling.
const borderRadius = 2;

const SlotDebug = styled.div`
  position: relative;
  z-index: 9999;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  flex: inherit;
  gap: inherit;
  width: inherit;
  min-height: ${themed('spacing.l')};

  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: ${rem(borderRadius)};
    border-top-left-radius: 0;
    border: dashed 1px ${themed('color.blue')};
  }

  &::after {
    content: attr(data-slot-id);
    box-sizing: border-box;
    position: absolute;
    bottom: calc(100% - 1px);
    left: 0;
    width: fit-content;
    height: fit-content;
    border-top-left-radius: ${rem(borderRadius)};
    border-top-right-radius: ${rem(borderRadius)};
    padding: ${themed('spacing.xs')};
    background-color: ${themed('color.blue')};
    color: ${themed('color.white')};
    ${themed('typography.h5')};
    font-size: ${rem(8)};
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
  }
`;

export interface SlotWrapperProps {
  'data-slot-content-id': string;
}

export const Slot = ({
  id,
  Container = DefaultContainer,
  Wrapper,
  dontRenderEmpty
}: {
  id: string;
  // Outer parent for all associated slot content elements
  Container?: React.ComponentType<{ children: React.ReactNode; 'data-slot-id': string }>;
  // Parent for individual slot content elements
  Wrapper?: React.ComponentType<SlotWrapperProps & { children: React.ReactNode }>;
  dontRenderEmpty?: boolean;
}) => {
  const { registerSlot, getSlotContent } = useSlots();
  const [_, triggerRerender] = useState(new Date());

  useEffect(() => {
    // Returns an unregister function, which is what we want to do (and return here in useEffect) on component unmount.
    return registerSlot(id, () => triggerRerender(new Date()));
  }, [id, registerSlot]);

  const shouldVisualiseSlot = useVisualiseSlot();

  const content = getSlotContent(id)
    .map(({ id, content }) => {
      if (!content) return null;

      return Wrapper ? (
        <Wrapper key={id} children={content} data-slot-content-id={id} />
      ) : (
        <React.Fragment key={id}>{content}</React.Fragment>
      );
    })
    .filter(Boolean);

  if (!content.length && dontRenderEmpty && !shouldVisualiseSlot) return null;

  if (!content.length) {
    return <Container data-slot-id={id}>{shouldVisualiseSlot && <SlotDebug data-slot-id={id} title={id} />}</Container>;
  }

  return (
    <Container data-slot-id={id}>
      {shouldVisualiseSlot ? (
        <SlotDebug data-slot-id={id} title={id}>
          {content}
        </SlotDebug>
      ) : (
        content
      )}
    </Container>
  );
};

/**
 * Used for a development build only.
 * Helps us visualise what slots are available in the layout.
 */
const useVisualiseSlot = (key = 'F1') => {
  const [shouldShowSlots, setShouldShowSlots] = useState(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (!import.meta.env.DEV || event.key !== key) return;
      setShouldShowSlots(true);
    };
    const onKeyUp = (event: KeyboardEvent) => {
      if (!import.meta.env.DEV || event.key !== key) return;
      setShouldShowSlots(false);
    };

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [key]);

  return shouldShowSlots;
};
