import { format, isAfter, isBefore, startOfDay } from 'date-fns';
import { pluralise } from 'utils';

export const auditTrailDateFormat = 'dd MMM yyyy';

export const countPC9s = (pc9s: string[]) => `${pc9s.length} ${pluralise(pc9s.length, 'PC9', 'PC9s')}`;

export const formatWithTimezone = (timestamp: string, formatting: string) => {
  const date = new Date(timestamp);
  return format(date.valueOf() - date.getTimezoneOffset() * 60 * 1000, formatting);
};

export const compareEventDates = (sortDir: 'asc' | 'desc') => (a: string, b: string) =>
  sortDir === 'asc' ? isBefore(startOfDay(a), startOfDay(b)) : isAfter(startOfDay(a), startOfDay(b));
