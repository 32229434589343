import { UpdateLinePayload } from '@yourxx/types';
import { ApiGetLineIn, ApiGetLineOut } from '@yourxx/types';

import { apiEndpoints, toRequest } from '../../utils';
import { apiClient } from '../client';

export const getLinesData = async (params: ApiGetLineIn): Promise<ApiGetLineOut> => {
  const request = toRequest({
    path: apiEndpoints.line,
    method: 'GET',
    params
  });
  return apiClient.request<ApiGetLineOut>(request);
};

export const updateLine = async ({ id, data: body }: { id: string; data: UpdateLinePayload }): Promise<any> => {
  const request = toRequest({ path: apiEndpoints.editLine({ lineId: id }), method: 'PATCH', body });
  return apiClient.request<any>(request);
};
