import { ApiPreRequestError } from '@yourxx/support';
import { AssortmentLineId, BoardId } from '@yourxx/types';

export const checkBoardId = (params?: BoardId) => {
  if (!params || !params.boardId) {
    throw new Error('Missing boardId from request');
  }
  return params.boardId;
};

export const checkBoardItemId = (params?: { boardItemId?: string }) => {
  if (!params || !params.boardItemId) {
    throw new ApiPreRequestError(`Missing boardItemId from request`);
  }
  return params.boardItemId;
};

export const checkLineAssortmentId = (params: AssortmentLineId) => {
  const lineAssortmentId = params?.assortmentId ?? params.lineId;
  if (!lineAssortmentId) {
    throw new ApiPreRequestError(`Missing Line or Assortment Id from request`);
  }
  return lineAssortmentId;
};

export const checkLineAssortmentIdFromId = (params?: { id?: string }) => {
  if (!params?.id) {
    throw new ApiPreRequestError(`Missing LineId or AssortmentId as parameter id from request`);
  }
  return params.id;
};
