import { isNumeric } from '@yourxx/support';
import { UserProfileContext } from '@yourxx/types';

import { awsRum } from '../';
import { decodeToken } from './jwt';

export const PersistedStoreKeys = {
  accessToken: 'access_token',
  refreshToken: 'refresh_token',
  userProfile: 'user_profile',
  imgFormat: 'image_format',
  loginUrl: 'login_url',
  logoutUrl: 'logout_url',
  redirectUrl: 'redirect_url',
  enableLogging: 'enable_logs',
  tokenExpiry: 'token_expiry',
  _loginProvider: 'login_provider',
  _selectedAssortmentId: 'selected_assortment_id',
  _selectedCustomerId: 'selected_customer_id',
  _loginUrls: 'login_urls'
};

export const persistedStore = {
  getAccessToken: () => window.localStorage.getItem(PersistedStoreKeys.accessToken),
  setAccessToken: (token: string) => {
    const decoded = decodeToken(token);
    window.localStorage.setItem(PersistedStoreKeys.accessToken, token);
    if (decoded && decoded?.exp) {
      persistedStore.setAccessTokenExpiry(decoded.exp * 1000);
    }
  },
  removeAccessToken: () => window.localStorage.removeItem(PersistedStoreKeys.accessToken),
  getAccessTokenExpiry: (): number | null => {
    const expiry = window.localStorage.getItem(PersistedStoreKeys.tokenExpiry);
    if (expiry && isNumeric(expiry)) {
      return Number(expiry);
    }
    return null;
  },
  setAccessTokenExpiry: (expiry?: number) => {
    if (expiry && isNumeric(expiry)) {
      window.localStorage.setItem(PersistedStoreKeys.tokenExpiry, `${new Date(expiry).getTime()}`);
    }
  },
  removeAccessTokenExpiry: () => window.localStorage.removeItem(PersistedStoreKeys.tokenExpiry),
  getRefreshToken: () => window.localStorage.getItem(PersistedStoreKeys.refreshToken),
  setRefreshToken: (token: string) => window.localStorage.setItem(PersistedStoreKeys.refreshToken, token),
  removeRefreshToken: () => window.localStorage.removeItem(PersistedStoreKeys.refreshToken),
  getUserProfile: () => {
    const userProfile = window.localStorage.getItem(PersistedStoreKeys.userProfile);
    return userProfile ? (JSON.parse(userProfile) as UserProfileContext) : null;
  },
  setUserProfile: (user: UserProfileContext) => {
    if (user) {
      awsRum?.addSessionAttributes({ userId: user.userId, role: user.role ?? 'Unknown' });
      window.localStorage.setItem(PersistedStoreKeys.userProfile, JSON.stringify(user));
    }
  },
  removeUserProfile: () => window.localStorage.removeItem(PersistedStoreKeys.userProfile),
  getImgFormat: () => window.localStorage.getItem(PersistedStoreKeys.imgFormat),
  setImgFormat: (imageFormat: string) => window.localStorage.setItem(PersistedStoreKeys.imgFormat, imageFormat),
  removeImgFormat: () => window.localStorage.removeItem(PersistedStoreKeys.imgFormat),
  getLoginUrl: (): string | null => window.localStorage.getItem(PersistedStoreKeys.loginUrl),
  setLoginUrl: (url: string) => {
    if (url) {
      window.localStorage.setItem(PersistedStoreKeys.loginUrl, url);
    }
  },
  removeLoginUrl: () => window.localStorage.removeItem(PersistedStoreKeys.loginUrl),
  getLogoutUrl: (): string | null => window.localStorage.getItem(PersistedStoreKeys.logoutUrl),
  setLogoutUrl: (url: string) => {
    if (url) {
      window.localStorage.setItem(PersistedStoreKeys.logoutUrl, url);
    }
  },
  removeLogoutUrl: () => window.localStorage.removeItem(PersistedStoreKeys.logoutUrl),
  getLoginRedirect: () => window.localStorage.getItem(PersistedStoreKeys.redirectUrl),
  setLoginRedirect: () => {
    const { pathname } = window.location;
    if (!pathname.match(new RegExp('^(/(v1|ui))?/(login|auth|logout)'))) {
      window.localStorage.setItem(PersistedStoreKeys.redirectUrl, pathname);
    }
  },
  removeLoginRedirect: () => window.localStorage.removeItem(PersistedStoreKeys.redirectUrl),
  clear: () => {
    for (const key of Object.values(PersistedStoreKeys)) {
      window.localStorage.removeItem(key);
    }
  }
};
