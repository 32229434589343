import { Entity, type EntityContext, type SidePanelId } from 'domain-events';
import { useCustomersData, useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssortmentAuditTrailEvent, type AuditTrailService, useServices } from 'services';
import { useIAM, useUserId } from 'utils';

import { Defect } from '@yourxx/support';
import { Event } from '@yourxx/types';

import { AuditTrailPanel } from '../AssortmentFinalization';
import { RemovedProducts } from '../AssortmentFinalization/RemovedProducts';
import { removedProductsFromEvents } from '../AssortmentFinalization/removedProductsFromEvents';
import { countPC9s } from '../AssortmentFinalization/utils';
import { useAuditTrail } from './useAuditTrail';
import type { UseSidePanelReturn } from './useSidePanel';

export const useAssortmentAuditTrailUI = ({
  context,
  sidePanel
}: {
  context: EntityContext | undefined;
  sidePanel: UseSidePanelReturn<SidePanelId>;
}) => {
  const [str] = useLocalisation();
  const { auditTrailService, productsService, toastService } = useServices();
  const { loadProductsFor, productsFor } = useCustomersData();
  const { canUse } = useIAM();
  const { isLoading, eventsFor, clearCache } = useAuditTrail(
    'assortment',
    auditTrailService as AuditTrailService<AssortmentAuditTrailEvent>
  );
  const currentUserId = useUserId();
  const [shouldLoadData, setShouldLoadData] = useState(false);

  const assortmentId =
    context?.entity === Entity.Assortment || context?.entity === Entity.FinalAssortment ? context.id : undefined;
  const events = canUse('header.viewAudit') && assortmentId && shouldLoadData ? eventsFor(assortmentId) : null;

  useEffect(() => {
    if (sidePanel.id === 'auditTrail') setShouldLoadData(true);
  }, [sidePanel.id]);

  const removed = useMemo(() => {
    if (!assortmentId) return [];

    const removedProductsData = productsFor(assortmentId, 'droppedAndRemoved');

    return removedProductsFromEvents(events ?? [], removedProductsData?.products ?? [], async product => {
      if (product.status === 'DROP') return;

      if (!assortmentId) throw new Defect('Cannot add product back to assortment: assortment id is not available.');

      if (!context) throw new Defect('Cannot add product back to assortment: context is not available.');

      return productsService
        .add({ context, items: [product] })
        .catch(error => toastService.send(<>{error instanceof Error ? error.message : `${error}`}</>, 'error'))
        .then(() => toastService.send(<>Product successfully added back into the assortment.</>, 'success'));
    });
  }, [assortmentId, context, events, productsFor, productsService, toastService]);

  const onViewingRemovedProducts = useCallback(() => {
    if (!assortmentId) return;
    loadProductsFor(assortmentId, 'droppedAndRemoved');
  }, [assortmentId, loadProductsFor]);

  const additionalTabs = useMemo(
    () => [
      {
        value: 'removed',
        label: str('AssortmentFinalization.auditTrail.tabs.removedProducts'),
        onActive: onViewingRemovedProducts,
        render: ({ sortDir }: { sortDir: 'asc' | 'desc' }) => <RemovedProducts sortDir={sortDir} products={removed} />
      }
    ],
    [onViewingRemovedProducts, removed, str]
  );

  const onRefresh = useCallback(() => {
    if (!assortmentId) return;
    clearCache(assortmentId);
    eventsFor(assortmentId);
  }, [assortmentId, clearCache, eventsFor]);

  const onClose = useCallback(() => sidePanel.set('auditTrail'), [sidePanel]);

  return (
    <AuditTrailPanel
      events={events}
      render={renderEvent(currentUserId.current)}
      isLoading={isLoading}
      isOpen={sidePanel.id === 'auditTrail'}
      onClose={onClose}
      onRefresh={onRefresh}
      additionalTabs={additionalTabs}
    />
  );
};

const renderEvent = (currentUserId: string) => (event: AssortmentAuditTrailEvent) => {
  const pc9s = event.payload.payload.pc9s;

  const title = (() => {
    const eventTriggerByCurrentUser = event.payload.created_by === currentUserId;
    const prefix = eventTriggerByCurrentUser
      ? 'You have'
      : event.payload.first_name || event.payload.last_name
        ? `${event.payload.first_name} ${event.payload.last_name} has`
        : 'YourXX has';

    switch (event.payload.name) {
      case Event.CREATE_ASSORTMENT:
        return `${prefix} created the assortment`;
      case Event.ADD_PC9S:
        return pc9s ? `${prefix} added ${countPC9s(pc9s)}` : '';
      case Event.REMOVE_PC9S:
        return pc9s ? `${prefix} removed ${countPC9s(pc9s)}` : '';
      case Event.DROP_PC9S:
        return pc9s ? `${prefix} dropped ${countPC9s(pc9s)}` : '';
      case Event.PUBLISH_ASSORTMENT:
        return `${prefix} published the assortment`;
      case Event.ARCHIVE_ASSORTMENT:
        return `${prefix} archived the assortment`;
      case Event.UNPUBLISH_ASSORTMENT:
        return `${prefix} unpublished the assortment`;
      default:
        return `Event type: ${event.payload.name}`;
    }
  })();

  return (
    <>
      <h4>{title}</h4>
      {pc9s && (
        <div>
          {pc9s.map((pc9, i, sortedEvents) => (
            <span key={`${event.payload.name}-${i}`}>
              {i === 0 && <span>&ldquo;</span>}
              {pc9}
              {i < sortedEvents.length - 2 && ', '}
              {i === sortedEvents.length - 2 && <span> &amp; </span>}
              {i === sortedEvents.length - 1 && <span>&rdquo;</span>}
            </span>
          ))}
        </div>
      )}
    </>
  );
};
