import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Content = styled.div<{ $minHeight?: number }>`
  overflow: auto;
  flex: 1;
  box-sizing: border-box;
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.regular')};
  padding: ${themed('spacing.xl')};
  min-height: ${({ $minHeight }) => rem($minHeight || 156)};
  background-color: ${themed('color.offWhite')};
`;
const Footer = styled.div<{ $oneButton?: boolean }>`
  display: flex;
  justify-content: ${({ $oneButton }) => ($oneButton ? 'center' : 'space-between')};
  gap: ${themed('spacing.xl')};
  position: sticky;
  bottom: 0;
  background-color: ${themed('color.white')};
  padding: ${themed('spacing.xl')};
  width: -webkit-fill-available;

  button {
    flex: ${({ $oneButton }) => ($oneButton ? 'none' : 1)};
  }
`;
const Confirm = styled(Button)<{ $isImportant?: boolean }>`
  user-select: none;
  background-color: ${({ $isImportant }) => themed($isImportant ? 'color.red' : 'color.black')};
  color: ${themed('color.white')};
  display: flex;
  width: ${rem(294)};
  height: ${rem(29)};
  justify-content: center;
  align-items: center;
  gap: ${rem(10)};
  flex-shrink: 0;
  ${themed('typography.h4')};

  &:disabled {
    background-color: ${themed('color.grey')};
  }
`;
const Cancel = styled(Button)`
  user-select: none;
  color: ${themed('color.black')};
  border: ${rem(1)} solid ${themed('color.black')};
  background-color: ${themed('color.white')};
  display: flex;
  width: ${rem(294)};
  height: ${rem(29)};
  justify-content: center;
  align-items: center;
  gap: ${rem(10)};
  flex-shrink: 0;
  ${themed('typography.h4')};
`;

type DialogProps = {
  title: string;
  subtitle?: string;
  content?: React.ReactNode;
  cancel?: { label: React.ReactNode; handler?: VoidFunction; disabled?: boolean };
  confirm?: { label: React.ReactNode; handler: VoidFunction; disabled?: boolean; important?: boolean };
  onClose: VoidFunction;
  maxWidth?: number;
  minHeight?: number;
};

export const Dialog = ({ title, subtitle, content, cancel, confirm, onClose, maxWidth, minHeight }: DialogProps) => (
  <Modal onClose={onClose} title={title} subtitle={subtitle} maxWidth={maxWidth}>
    <Content $minHeight={minHeight}>{content}</Content>
    {(cancel || confirm) && (
      <Footer $oneButton={(cancel && !confirm) || (confirm && !cancel)}>
        {cancel && (
          <Cancel onClick={cancel.handler || onClose} disabled={cancel.disabled}>
            {cancel.label}
          </Cancel>
        )}
        {confirm && (
          <Confirm onClick={confirm.handler} disabled={confirm.disabled} $isImportant={confirm.important}>
            {confirm.label}
          </Confirm>
        )}
      </Footer>
    )}
  </Modal>
);
