import { AuditTrailEvent } from './AuditTrailEvent';
import type { AuditTrailService } from './AuditTrailService';

type Payload = any; // TODO: Define BE-provided data shape.

export class OrderAuditTrailEvent extends AuditTrailEvent {
  static of(payload: Payload, service: AuditTrailService) {
    return new OrderAuditTrailEvent(payload, service);
  }

  entityType = 'order';

  // TODO: Update after payload data shape is known from the API
  get entityId() {
    return this.payload.item_id;
  }

  // TODO: Update after payload data shape is known from the API
  get createdAt() {
    return this.payload.created_at;
  }

  private constructor(
    public readonly payload: Readonly<Payload>,
    service: AuditTrailService
  ) {
    super(service);
  }
}
