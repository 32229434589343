import { CopyAlt, Edit } from 'assets/icons';
import { Dialog } from 'components';
import { format } from 'date-fns';
import { SlotId, type ToolbarItemProps, useAddPageToolbarControl } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useMemo, useState } from 'react';
import { OrderStatus, useServices } from 'services';

import { OrderMetadata, StatusCapsule } from '../components';

const edit: ToolbarItemProps = {
  icon: <Edit />,
  label: undefined,
  hint: 'Edit order',
  onClick: () => console.log('You clicked "Edit order"'),
  isVisible: true,
  isDisabled: false
};

export const EditOrderDemo = () => {
  const { toastService } = useServices();

  const editButton = useMemo(
    () => ({
      ...edit,
      onClick: () => {
        edit.onClick?.();
        toastService.send(`You clicked ${edit.hint}`);
      }
    }),
    [toastService]
  );
  useAddPageToolbarControl(editButton, 0);

  return null;
};

export const OrderMetadataDemo = ({ orderId }: { orderId: string }) => {
  useSlot(SlotId.PageTitle, `Order: ${orderId}`);

  useSlot(
    SlotId.Status,
    useMemo(() => <StatusCapsule status={OrderStatus.InProgress} />, [])
  );

  useSlot(
    SlotId.PageDetails,
    useMemo(
      () => (
        <OrderMetadata
          metadata={{
            Order: '0000000000',
            'Order Deadline': format(new Date(), 'dd MMM yyyy'),
            Location: ['xxx', 'yyy'].join(', ')
          }}
        ></OrderMetadata>
      ),
      []
    )
  );

  return null;
};

const copy: ToolbarItemProps = {
  icon: <CopyAlt />,
  label: 'Copy order label'
};

const useCopyOrderModalDemo = () => {
  const [str] = useLocalisation();
  const [isOpen, setIsOpen] = useState(false);

  const copyButton = useMemo(() => ({ ...copy, isActive: isOpen, onClick: () => setIsOpen(p => !p) }), [isOpen]);
  useAddPageToolbarControl(copyButton, 1);

  useSlot(
    SlotId.Modals,
    useMemo(() => {
      const onClose = () => setIsOpen(false);

      return (
        isOpen && (
          <Dialog
            title="Slot Modal Demo"
            onClose={onClose}
            cancel={{ label: str('general.cancel'), handler: onClose }}
          />
        )
      );
    }, [isOpen, str])
  );

  return null;
};

export const CopyOrderModalDemo = () => {
  useCopyOrderModalDemo();
  return null;
};
