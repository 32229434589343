import { PrimaryButton, ShowAsyncErrorAndGoBack, SlotId, ViewTransition } from 'pages';
import { OrderSummary as IOrderSummary, useLocalisation, useOrders, useSlot } from 'providers';
import { Suspense, useMemo } from 'react';
import { Await, Link } from 'react-router-dom';
import { useServices } from 'services';

import { OrderMenu, SetupOrderToolbar } from '../components';
import { OrderSummarySkeleton } from './OrderSummarySkeleton';

export const OrderSummary = ({ orderId }: { orderId: string }) => {
  const { loadOrderSummary } = useOrders();
  return (
    <Suspense fallback={<ViewTransition children={<OrderSummarySkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadOrderSummary(orderId)}
        children={data => <ViewTransition children={<View data={data} />} />}
        // children={_data => <ViewTransition children={<OrderSummarySkeleton />} />}
      />
    </Suspense>
  );
};

const View = ({ data: { customerId, orderId, summary } }: { data: IOrderSummary }) => {
  const { toastService } = useServices();

  useSlot(
    SlotId.OrderMenu,
    useMemo(() => <OrderMenu />, [])
  );

  useSlot(
    SlotId.FooterRight,
    useMemo(
      () => <PlaceOrdersButton onClick={() => toastService.send('You clicked the "Place Orders" button.')} />,
      [toastService]
    )
  );

  return (
    <>
      <SetupOrderToolbar orderId={orderId} />
      [Order Summary [{customerId}] [{orderId}]]
      <div>{summary}</div>
      <ul>
        <li>
          <Link to="001V3-0001">001V3-0001</Link>
        </li>
        <li>
          <Link to="12501-0554">12501-0554</Link>
        </li>
        <li>
          <Link to="36200-0350">36200-0350</Link>
        </li>
      </ul>
    </>
  );
};

const PlaceOrdersButton = ({ onClick }: { onClick: VoidFunction }) => {
  const [str] = useLocalisation();
  return <PrimaryButton onClick={onClick}>{str('Order.placeOrders')}</PrimaryButton>;
};
