import { ValidationFailures } from '@yourxx/types';

export type PostgresErrorInput = {
  type?: PostgresErrors;
  message: string;
};

export enum PostgresErrors {
  POSTGRES_ERROR = 'PostgresError',
  POSTGRES_CONN_TIMEOUT_ERROR = 'PostgresError.ConnectionWaitTimeout',
  POSTGRES_CONN_FAILED = 'PostgresError.FailedToConnect',
  POSTGRES_INSERT_QUERY_FAILED = 'PostgresError.InsertQueryRolledBack'
}

export enum ImageResizerErrors {
  VALIDATION_ERROR = 'Validation.Error'
}

export class ValidationError extends Error {
  constructor(errors: ValidationFailures[]) {
    const message = JSON.stringify(errors);
    super(message);
    this.name = ImageResizerErrors.VALIDATION_ERROR;
    this.message = message;
  }
}

export class PostgresError extends Error {
  constructor(params: PostgresErrorInput) {
    const { message } = params;
    super(message);
    this.name = this.errorType(params);
  }

  private errorType(params: PostgresErrorInput): PostgresErrors {
    const { type, message } = params;
    if (message.includes('Timed-out waiting to acquire database connection')) {
      return PostgresErrors.POSTGRES_CONN_TIMEOUT_ERROR;
    }
    if (type) {
      return type;
    }
    return PostgresErrors.POSTGRES_ERROR;
  }
}

export class ApiPreRequestError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError.RequestNotSent';
    this.message = message;
  }
}

export class InvalidSortKey extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataIngestion.InvalidSortKey';
    this.message = message;
  }
}
export class UnsupportedIngestionType extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataIngestion.UnsupportedProcess';
    this.message = message;
  }
}
export class DynamoDbScalingError extends Error {
  constructor() {
    super('Throughput exceeds the current capacity of your table or index');
    this.name = 'Dynamodb.DynamoDbScaling';
  }
}

export class NotImplementedError extends Error {
  constructor() {
    super('Not implemented');
  }
}
