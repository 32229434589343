import { AnyFilter, FiltersSchema } from '@yourxx/support';
import { equals } from '@yourxx/support';
import { toSorted } from '@yourxx/support';

import { setFilterInCombination } from './setFilterInCombination';

export type FilterCombinationId = string & { readonly _kind: unique symbol };

export interface FilterCombination {
  readonly id: FilterCombinationId;
  readonly name: string;
  filters: FiltersSchema;

  equals(other: FilterCombination): boolean;

  filtersEqual(other: FilterCombination): boolean;

  applyFilters(filters: readonly AnyFilter[]): this;
}

export const FilterCombination = (id: string, name: string, filters: FiltersSchema = []): FilterCombination => {
  const fc: FilterCombination = {
    id: id as FilterCombinationId,
    name,
    filters,
    applyFilters(newFilters) {
      return newFilters.reduce(
        (final, filter) => setFilterInCombination(final, filter),
        FilterCombination(id, name, filters)
      );
    },
    equals(other) {
      return equals(id, other.id) && equals(name, other.name) && fc.filtersEqual(other);
    },
    filtersEqual(other) {
      return filtersEqual(filters, other.filters);
    }
  };

  return fc;
};

const sortedFilters = (xs: readonly AnyFilter[]): AnyFilter[] =>
  toSorted<AnyFilter>(xs, (a, b) => (a.id > b.id ? -1 : 0));

export const filtersEqual = (xs: readonly AnyFilter[], ys: readonly AnyFilter[]): boolean => {
  const sortedXs = sortedFilters(xs);
  const sortedYs = sortedFilters(ys);
  if (sortedXs.length !== sortedYs.length) return false;
  return sortedXs.every((x, i) => x.equals(sortedYs[i]));
};

export const combinationEquals = (a: FilterCombination, b: FilterCombination) => {
  return equals(a.id, b.id) && equals(a.name, b.name) && filtersEqual(a.filters, b.filters);
};
